import React, { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { useTheme } from "@noones/ui-kit";

import { ProfileIntlProvider } from "../lang/ProfileIntlProvider";
import { getLocale } from "../lib/locale";
import { selectApp } from "../store";
import { LoaderMaskedSurface } from "./components";
import { useAppSelector } from "./store/hooks";

const SignUp = lazy(() =>
    import(/* webpackChunkName: "signUp" */ "../features/auth/account/pages").then((module) => ({
        default: module.SignUp,
    })),
);

const SignIn = lazy(() =>
    import(/* webpackChunkName: "signIn" */ "../features/auth/account/pages").then((module) => ({
        default: module.SignIn,
    })),
);

const SignUpByInvitation = lazy(() =>
    import(/* webpackChunkName: "signUpByInvitation" */ "../features/auth/account/pages").then(
        (module) => ({
            default: module.SignUpByInvitation,
        }),
    ),
);

const ResetPassword = lazy(() =>
    import(/* webpackChunkName: "resetPassword" */ "../features/auth/account/pages").then(
        (module) => ({
            default: module.ResetPassword,
        }),
    ),
);

const ResetTwoFAInquiry = lazy(() =>
    import(/* webpackChunkName: "resetPassword" */ "../features/auth/account/pages").then(
        (module) => ({
            default: module.ResetTwoFAInquiry,
        }),
    ),
);

const ResetTwoFA = lazy(() =>
    import(/* webpackChunkName: "resetTwoFA" */ "../features/auth/account/pages").then(
        (module) => ({
            default: module.ResetTwoFA,
        }),
    ),
);

const SocialMediaSignIn = lazy(() =>
    import(/* webpackChunkName: "socialMediaSignIn" */ "../features/auth/account/pages").then(
        (module) => ({
            default: module.SocialMediaSignIn,
        }),
    ),
);

const SocialMediaSignUp = lazy(() =>
    import(/* webpackChunkName: "socialMediaSignUp" */ "../features/auth/account/pages").then(
        (module) => ({
            default: module.SocialMediaSignUp,
        }),
    ),
);

export const Profile = () => {
    const theme = useTheme();
    document.body.style.background = theme.colors.backgroundDefault[0];
    const { locale } = useAppSelector(selectApp);
    const baseName = window.location.pathname.startsWith("/id/") ? "/id/" : "/";
    return (
        <ProfileIntlProvider locale={getLocale(locale)}>
            <meta name="theme-color" content={theme.colors.backgroundDefault[0]} />
            <BrowserRouter basename={baseName}>
                <Suspense fallback={<LoaderMaskedSurface />}>
                    <Routes>
                        <Route path="*" element={<Navigate to="/login" />} />
                        <Route path="/signup/" element={<SignUp />} />
                        <Route path="/login/" element={<SignIn />} />
                        <Route path="/reset-password/" element={<ResetPassword />} />
                        <Route path="/reset-2fa/:inquiryId" element={<ResetTwoFAInquiry />} />
                        <Route path="/reset-2fa/" element={<ResetTwoFA />} />
                        <Route path="/register/" element={<SignUpByInvitation />} />
                        <Route
                            path="/register/google/"
                            element={<SocialMediaSignUp type="google" />}
                        />
                        <Route
                            path="/login/google/"
                            element={<SocialMediaSignIn type="google" />}
                        />
                        <Route
                            path="/register/facebook/"
                            element={<SocialMediaSignUp type="facebook" />}
                        />
                        <Route
                            path="/login/facebook/"
                            element={<SocialMediaSignIn type="facebook" />}
                        />
                        <Route
                            path="/register/apple/"
                            element={<SocialMediaSignUp type="apple" />}
                        />
                        <Route path="/login/apple/" element={<SocialMediaSignIn type="apple" />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </ProfileIntlProvider>
    );
};
