import { FeatureFlag, isFeatureFlagSet } from "lib/storage";
import { v4 as uuidv4 } from "uuid";

import { AuthType } from "./types";

export type paramsType = {
    action: string;
    label: string;
};

const VISITOR_ID = uuidv4();

const defaultStreamerParams = {
    app_name: "profile",
    app_type: "fe",
    visitor_id: VISITOR_ID,
    timestamp: Date.now(),
};

const sendTrackEvent = (data: string) => {
    const url = `${window.streamerPublicUrl}/events`;
    if (navigator.sendBeacon) {
        navigator.sendBeacon(url, data);
    } else {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.send(data);
    }
};

const track = (params: paramsType, additionalAttributes = {}) => {
    const data = JSON.stringify({
        ...defaultStreamerParams,
        name: params.action,
        attributes: {
            documentLocation:
                document.location.origin + document.location.pathname + document.location.search,
            userLanguage: navigator ? (navigator.language || "").toLowerCase() : undefined,
            screenColors: screen.colorDepth ? `${screen.colorDepth}-bits` : undefined,
            screenResolution: `${(window.screen || {}).width}x${(window.screen || {}).height}`,
            viewpointSize: window.visualViewport
                ? `${(window.visualViewport || {}).width}x${(window.visualViewport || {}).height}`
                : undefined,
            documentEncoding: document.characterSet,
            documentReferrer: document.referrer,
            documentTitle: document.title,
            userAgent: navigator.userAgent,
            label: params.label,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            uuid: uuidv4(),
            [FeatureFlag.USERNAME_SKIP]: isFeatureFlagSet(FeatureFlag.USERNAME_SKIP),
            [FeatureFlag.MERGE_EMAIL_PASSWORD]: isFeatureFlagSet(FeatureFlag.MERGE_EMAIL_PASSWORD),
            [FeatureFlag.STEP1_NEW]: isFeatureFlagSet(FeatureFlag.STEP1_NEW),
            ...additionalAttributes,
        },
    });

    sendTrackEvent(data);
};

export const registrationEvent = (authType: AuthType) => {
    const type = authType.charAt(0).toUpperCase() + authType.slice(1);
    const params = {
        action: `Sign Up via ${type}`,
        label: `Success Registration via ${type}`,
    };
    track(params, { authType });
};
