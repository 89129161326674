import camelcaseKeys from "camelcase-keys";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../app/store/store";
import { readFromStorage, StorageKeys, writeToStorage } from "../../lib/storage";
import { authApi } from "../../services/authApi";
import { capture, SentryError } from "../../services/sentry";
import { registrationEvent } from "../../services/streamer";
import {
    AuthType,
    GetReferralCodeRequest,
    InvitationWarningCode,
    LoginMfaChallengeParameters,
    PasswordRegisterChallengeParameters,
    RegisterChallengeType,
    SetSocialMediaCodeArg,
    SetSocialMediaErrorArg,
    SocialMediaAccountType,
    TwoFAError,
} from "../../services/types";
import { appGetCountries, getSettings } from "../app/app";
import { COMPROMISED_ACCOUNT_ERROR_CODE } from "../constants";

export type ReferralUser = {
    uid: string;
    avatarUrl?: string;
    username: string;
    bio: string;
    badges: [];
    referrerRevenueShare?: string;
    feedbackPositive: number;
    feedbackNegative: number;
    tradesCountTotal?: number;
    tradesPercentSuccess?: number;
};

export type InviteStateType = {
    initCompleted: boolean;
    isLoading: boolean;
    isCheckLoading: boolean;
    socialMediaCode?: string;
    socialMediaError?: string;
    socialMediaType?: SocialMediaAccountType;
    email?: string;
    loginSessionId?: string;
    isGoogleLogin?: boolean;
    isFacebookLogin?: boolean;
    isAppleLogin?: boolean;
    inviteCodeError: string;
    inviteWarningCode?: string;
    referralCode?: string;
    isEditCodeModalOpen: boolean;
    isSignUpRewardModalOpen: boolean;
    isTradingDiscountModalOpen: boolean;
    isInviteAndEarnModalOpen: boolean;
    isComplexRegulationModalOpen: boolean;
    isModalLinkVisible: boolean;
    isSettingsError: boolean;
    isCountriesError: boolean;
    isError: boolean;
    mfaSessionId?: string;
    isCompromised: boolean;
    referralUser?: ReferralUser;
};

const initialState: InviteStateType = {
    initCompleted: false,
    isLoading: false,
    isCheckLoading: false,
    socialMediaCode: "",
    socialMediaError: "",
    socialMediaType: undefined,
    email: "",
    loginSessionId: "",
    isGoogleLogin: false,
    isFacebookLogin: false,
    isAppleLogin: false,
    inviteCodeError: "",
    inviteWarningCode: "",
    referralCode: undefined,
    isEditCodeModalOpen: false,
    isSignUpRewardModalOpen: false,
    isTradingDiscountModalOpen: false,
    isInviteAndEarnModalOpen: false,
    isComplexRegulationModalOpen: false,
    isModalLinkVisible: false,
    isSettingsError: false,
    isCountriesError: false,
    isError: false,
    mfaSessionId: undefined,
    isCompromised: false,
};

export type CheckReferralCodeResponse = {
    referralCode?: string;
    referralUser: ReferralUser;
};

export type InviteError = {
    code: string;
    message: string;
    http_code: number;
};

const GOOGLE_ACCOUNT_ALREADY_EXISTS = "googleAccountAlreadyExists";
const FACEBOOK_ACCOUNT_ALREADY_EXISTS = "facebookAccountAlreadyExists";
const APPLE_ACCOUNT_ALREADY_EXISTS = "appleAccountAlreadyExists";

export const init = createAsyncThunk("welcome/INIT", async (arg, { dispatch, getState }) => {
    await dispatch(getSettings());
    await dispatch(appGetCountries());

    const state = getState() as RootState;
    const { socialMediaCode: code, socialMediaType: type, socialMediaError: error } = state.invite;
    const {
        registrationInfo: { citizenshipConsentRequired },
        config: { marketplaceUrl, marketplaceClientId, accountsAuthorizeUrl },
    } = state.app;

    writeToStorage(
        StorageKeys.REDIRECT_URL,
        readFromStorage(StorageKeys.REDIRECT_URL) || marketplaceUrl,
    );
    writeToStorage(
        StorageKeys.CLIENT_ID,
        readFromStorage(StorageKeys.CLIENT_ID) || marketplaceClientId,
    );
    writeToStorage(StorageKeys.ACCOUNTS_AUTHORIZE_URL, accountsAuthorizeUrl);

    if (error) {
        const transactionName = `${type}SignUpError`;

        dispatch(setInviteWarningError(InvitationWarningCode.SOCIAL_MEDIA_ERROR));
        capture({
            error: new SentryError("OAuth 2.0 sign-up error", error),
            transactionName,
            fingerprint: ["react", error],
            tags: { ui: true, "ui-branch": "beta" },
            context: { info: { error } },
        });
        dispatch(setInitCompleted(true));
    } else if (code) {
        if (citizenshipConsentRequired) {
            dispatch(setComplexRegulationModalOpen(true));
            dispatch(setInitCompleted(true));
        } else {
            dispatch(registerUserWithSocialMediaAccount({ code, type }));
        }
    } else {
        dispatch(setInitCompleted(true));
    }
});

export const checkReferralCode = createAsyncThunk<
    CheckReferralCodeResponse,
    GetReferralCodeRequest,
    { rejectValue: TwoFAError }
>("welcome/CHECK_REFERRAL_CODE", async ({ referralCode }, { dispatch, rejectWithValue }) => {
    const result = dispatch(
        authApi.endpoints.getReferralCode.initiate({
            referralCode,
        }),
    );

    return result
        .unwrap()
        .then((res) => {
            writeToStorage(StorageKeys.REFERRAL_CODE, referralCode);
            return {
                referralCode,
                referralUser: camelcaseKeys(res),
            };
        })
        .catch((error) => rejectWithValue(error.data))
        .finally(result.reset);
});

export const registerUserWithSocialMediaAccount = createAsyncThunk<
    {
        initCompleted: boolean;
        isModalLinkVisible?: boolean;
        email?: string;
        loginSessionId?: string;
        mfaSessionId?: string;
    },
    { code?: string; type?: SocialMediaAccountType; isNotCitizen?: boolean },
    { rejectValue: InviteError }
>(
    "welcome/REGISTER_USER",
    async ({ code, type, isNotCitizen }, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const { locale } = state.app;
        const refCode = readFromStorage(StorageKeys.REFERRAL_CODE);
        if (isNotCitizen) {
            writeToStorage(StorageKeys.IS_NOT_CITIZEN_ACCEPTED, String(Boolean(isNotCitizen)));
        }
        const result = dispatch(
            authApi.endpoints.register.initiate({
                type,
                code,
                ...(isNotCitizen && { citizenship_consent_accepted: isNotCitizen }),
                ...(refCode && { referral_code: refCode }),
                locale: locale,
                platform: "web",
                client_id: readFromStorage(StorageKeys.CLIENT_ID),
                app_version: "0.0.1",
                device_id: window.navigator.userAgent,
            }),
        );

        return result
            .unwrap()
            .then((data) => {
                const loginToken = data?.auth_result?.login_token;
                let initCompleted = false;
                let isModalLinkVisible = false;
                let email = "";
                let loginSessionId;
                let mfaSessionId;
                if (loginToken) {
                    writeToStorage(StorageKeys.LOGIN_TOKEN, loginToken);
                    writeToStorage(StorageKeys.LAST_USERNAME, data?.username);
                    writeToStorage(StorageKeys.SESSION_ID, data?.register_session_id);
                    registrationEvent(type as AuthType);
                    window.location.href = "/id/signup";
                } else {
                    initCompleted = true;
                    const passwordChallenge = data?.challenges?.filter(
                        (challenge) => challenge?.challenge_name === RegisterChallengeType.password,
                    )[0];

                    const mfaChallenge = data?.challenges.filter(
                        (challenge) => challenge?.challenge_name === RegisterChallengeType.mfa,
                    )[0];
                    if (passwordChallenge) {
                        isModalLinkVisible = true;
                        const passwordParams =
                            passwordChallenge.challenge_parameters as PasswordRegisterChallengeParameters;
                        email = passwordParams?.email;
                        loginSessionId = data?.login_session_id;
                    }
                    if (mfaChallenge) {
                        const mfaChallengeParams =
                            mfaChallenge.challenge_parameters as LoginMfaChallengeParameters;
                        loginSessionId = data?.login_session_id;
                        mfaSessionId = mfaChallengeParams.mfa_session_id;
                    }
                }
                return {
                    initCompleted,
                    isModalLinkVisible,
                    email,
                    loginSessionId,
                    mfaSessionId,
                };
            })
            .catch((error) => rejectWithValue(error.data))
            .finally(result.reset);
    },
);

export const invite = createSlice({
    name: "invite",
    initialState,
    reducers: {
        setInitCompleted(draft, { payload }: PayloadAction<boolean>) {
            draft.initCompleted = payload;
        },
        setSocialMediaCode(draft, { payload }: PayloadAction<SetSocialMediaCodeArg>) {
            draft.socialMediaCode = payload.code;
            draft.socialMediaType = payload.type;
        },
        setSocialMediaError(draft, { payload }: PayloadAction<SetSocialMediaErrorArg>) {
            draft.socialMediaError = payload.error;
            draft.socialMediaType = payload.type;
        },
        clearSocialMediaData(draft) {
            draft.socialMediaType = undefined;
            draft.socialMediaCode = "";
            draft.socialMediaError = "";
            draft.loginSessionId = "";
        },
        setEditCodeModalOpen(draft, { payload }) {
            draft.isEditCodeModalOpen = payload;
        },
        setSignUpRewardModalOpen(draft, { payload }) {
            draft.isSignUpRewardModalOpen = payload;
        },
        setTradingDiscountModalOpen(draft, { payload }) {
            draft.isTradingDiscountModalOpen = payload;
        },
        setInviteAndEarnModalOpen(draft, { payload }) {
            draft.isInviteAndEarnModalOpen = payload;
        },
        setComplexRegulationModalOpen(draft, { payload }) {
            draft.isComplexRegulationModalOpen = payload;
        },
        setModalLinkVisible(draft, { payload }: PayloadAction<boolean>) {
            draft.isModalLinkVisible = payload;
        },
        setInviteCodeError(draft, { payload }: PayloadAction<string>) {
            draft.inviteCodeError = payload;
        },
        setInviteWarningError(draft, { payload }) {
            draft.inviteWarningCode = payload;
        },
        setCompromisedModalOpen(draft, { payload }: PayloadAction<boolean>) {
            draft.isCompromised = payload;
        },
        setReferralCode(draft, { payload }: PayloadAction<string>) {
            draft.referralCode = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(checkReferralCode.pending, (draft) => {
            draft.isCheckLoading = true;
            draft.inviteCodeError = "";
        });

        builder.addCase(checkReferralCode.fulfilled, (draft, { payload }) => {
            const { referralCode, referralUser } = payload;

            draft.isCheckLoading = false;
            draft.isEditCodeModalOpen = false;
            draft.referralCode = referralCode;
            draft.referralUser = referralUser;
        });

        builder.addCase(checkReferralCode.rejected, (draft, { payload }) => {
            draft.isCheckLoading = false;
            draft.referralUser = undefined;
            if (payload?.code) {
                draft.inviteCodeError = payload?.code;
            }
        });

        builder.addCase(appGetCountries.rejected, (draft) => {
            draft.initCompleted = true;
            draft.isCountriesError = true;
        });

        builder.addCase(getSettings.rejected, (draft) => {
            draft.initCompleted = true;
            draft.isSettingsError = true;
        });

        builder.addCase(appGetCountries.fulfilled, (draft) => {
            draft.isCountriesError = false;
        });

        builder.addCase(getSettings.fulfilled, (draft) => {
            draft.isSettingsError = false;
        });

        builder.addCase(registerUserWithSocialMediaAccount.pending, (draft) => {
            draft.isLoading = true;
            draft.inviteWarningCode = undefined;
        });

        builder.addCase(registerUserWithSocialMediaAccount.fulfilled, (draft, { payload }) => {
            draft.isLoading = false;
            draft.initCompleted = payload.initCompleted;
            if (payload.isModalLinkVisible) {
                draft.isModalLinkVisible = payload.isModalLinkVisible;
            }
            if (payload.email) {
                draft.email = payload.email;
            }
            if (payload.loginSessionId) {
                draft.loginSessionId = payload.loginSessionId;
            }
            draft.mfaSessionId = payload.mfaSessionId;
        });

        builder.addCase(registerUserWithSocialMediaAccount.rejected, (draft, { payload }) => {
            draft.socialMediaCode = "";
            if (payload) {
                if (payload.code === GOOGLE_ACCOUNT_ALREADY_EXISTS) {
                    draft.isGoogleLogin = true;
                } else if (payload.code === FACEBOOK_ACCOUNT_ALREADY_EXISTS) {
                    draft.isFacebookLogin = true;
                } else if (payload.code === APPLE_ACCOUNT_ALREADY_EXISTS) {
                    draft.isAppleLogin = true;
                } else if (payload.code === COMPROMISED_ACCOUNT_ERROR_CODE) {
                    draft.isCompromised = true;
                } else {
                    draft.inviteWarningCode = payload.code;
                }
            } else {
                draft.isError = true;
            }
            if (!draft.isGoogleLogin && !draft.isFacebookLogin && !draft.isAppleLogin) {
                draft.isLoading = false;
                draft.initCompleted = true;
            }
        });
    },
});

export const {
    setInitCompleted,
    setSocialMediaCode,
    setSocialMediaError,
    clearSocialMediaData,
    setEditCodeModalOpen,
    setSignUpRewardModalOpen,
    setTradingDiscountModalOpen,
    setInviteAndEarnModalOpen,
    setComplexRegulationModalOpen,
    setModalLinkVisible,
    setInviteCodeError,
    setInviteWarningError,
    setCompromisedModalOpen,
    setReferralCode,
} = invite.actions;
