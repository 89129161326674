import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../app/store/store";
import { AccountMethodType } from "../../features/auth/account/form/controls/AccountMethod";
import { GeetestSuccessResponse } from "../../features/geetest/geetest";
import { GeetestData } from "../../features/geetest/GeetestData";
import { castle } from "../../lib/castle_io";
import { errorReturnStageResetPassword } from "../../lib/returnStage";
import { readFromStorage, StorageKeys } from "../../lib/storage";
import { authApi } from "../../services/authApi";
import {
    BusinessErrorCode,
    CaptchaChallengeParameters,
    CaptchaChallengeResponse,
    DeliveryType,
    ForgotPasswordChallengeType,
    ForgotPasswordMailChallengeParameters,
    ForgotPasswordPhoneChallengeParameters,
    ForgotPasswordRespondToChallengeRequest,
    MfaChallengeType,
    TwoFAError,
    TwoFAErrorCode,
} from "../../services/types";
import { appGetCountries, getSettings } from "../app/app";

export enum ResetPasswordStage {
    DATA = 0,
    TWO_FA = 1,
    PASSWORD = 2,
}

export type ResetPasswordState = {
    initCompleted: boolean;
    accountMethod: AccountMethodType;
    stage: ResetPasswordStage;
    phone: string;
    email: string;
    password: string;
    forgotPasswordSessionId: string;
    captchaChallenge: GeetestData;
    twoFATitle: string;
    twoFAChallengeType: MfaChallengeType;
    isPasswordSuccessfullyChanged: boolean;
    redirectUrl: string;
    isLoading: boolean;
    isCaptchaError: boolean;
    isError: boolean;
    businessErrorCode?: string;
    twoFAError?: TwoFAError;
    challengeNames: Array<ForgotPasswordChallengeType>;
};

const initialState: ResetPasswordState = {
    initCompleted: false,
    accountMethod:
        (readFromStorage(StorageKeys.ACTIVE_TAB) as AccountMethodType) || AccountMethodType.EMAIL,
    stage: ResetPasswordStage.DATA,
    phone: readFromStorage(StorageKeys.PHONE),
    email: readFromStorage(StorageKeys.EMAIL),
    password: "",
    forgotPasswordSessionId: "",
    captchaChallenge: {} as GeetestData,
    twoFATitle: "",
    twoFAChallengeType: MfaChallengeType.phone,
    isPasswordSuccessfullyChanged: false,
    redirectUrl: "",
    isLoading: false,
    isCaptchaError: false,
    isError: false,
    businessErrorCode: undefined,
    twoFAError: undefined,
    challengeNames: [],
};

export type ForgotPasswordAsyncThunkRequest = {
    phone: string;
    email: string;
};

export type ForgotPasswordAsyncThunkResponse = {
    forgotPasswordSessionId: string;
    captchaChallenge: GeetestData;
    phone: string;
    challengeNames: Array<ForgotPasswordChallengeType>;
};

export type RespondToChallengeAsyncThunkResponse = {
    stage?: ResetPasswordStage;
    twoFATitle: string;
    twoFAChallengeType: MfaChallengeType;
    challengeNames: Array<ForgotPasswordChallengeType>;
};

export type ConfirmForgotPasswordResponse = {
    isRedirect: boolean;
};

export type ForgotPasswordError = {
    code: string;
    message: string;
    http_code: number;
};

export const init = createAsyncThunk("reset-password/INIT", async (args, { dispatch }) => {
    await dispatch(appGetCountries());
    return dispatch(getSettings());
});

export const forgotPassword = createAsyncThunk<
    ForgotPasswordAsyncThunkResponse,
    ForgotPasswordAsyncThunkRequest,
    { rejectValue: ForgotPasswordError }
>("reset-password/FORGOT_PASSWORD", async ({ phone, email }, { dispatch, rejectWithValue }) => {
    const result = dispatch(
        authApi.endpoints.forgotPassword.initiate({
            ...(email ? { email } : { phone }),
        }),
    );

    return result
        .unwrap()
        .then((data) => {
            let captcha = {} as GeetestData;
            const { challenges, forgot_password_session_id: forgotPasswordSessionId } = data;

            if (challenges) {
                const captchaChallenge = challenges.filter(
                    (challenge) =>
                        challenge?.challenge_name === ForgotPasswordChallengeType.geetest,
                )[0];
                if (captchaChallenge) {
                    const geetestParams =
                        captchaChallenge.challenge_parameters as CaptchaChallengeParameters;
                    captcha = {
                        captchaId: geetestParams.captcha_id,
                        challenge: geetestParams.challenge,
                        newCaptcha: true,
                        offline: geetestParams.offline,
                        lang: "en",
                        hideClose: true,
                    };
                }
            }

            return {
                forgotPasswordSessionId,
                captchaChallenge: captcha,
                phone,
                challengeNames: challenges.map((challenge) => challenge.challenge_name),
            };
        })
        .catch((error) => rejectWithValue(error.data))
        .finally(result.reset);
});

export const enrichWithCastleIOChallenge = async (
    challengeNames: Array<ForgotPasswordChallengeType>,
    challengeData: ForgotPasswordRespondToChallengeRequest,
) => {
    if (challengeNames.includes(ForgotPasswordChallengeType.castleio)) {
        const castleIORequestToken = await castle.createRequestToken();
        challengeData.challenges.push({
            challenge_name: ForgotPasswordChallengeType.castleio,
            challenge_response: {
                castleio_request_token: castleIORequestToken,
            },
        });
    }
};

export const respondToChallengeWithCaptcha = createAsyncThunk(
    "reset-password/RESPOND_TO_CHALLENGE_CAPTCHA",
    async (args: GeetestSuccessResponse, { dispatch, getState }) => {
        const state = getState() as RootState;
        const { forgotPasswordSessionId, captchaChallenge, challengeNames } =
            state[resetPassword.name];
        const challengeResponse: CaptchaChallengeResponse = {
            challenge: captchaChallenge.challenge,
            seccode: args.geetest_seccode,
            validate: args.geetest_validate,
        };

        const challengeData: ForgotPasswordRespondToChallengeRequest = {
            sessionId: forgotPasswordSessionId,
            challenges: [
                {
                    challenge_name: ForgotPasswordChallengeType.geetest,
                    challenge_response: challengeResponse,
                },
            ],
        };

        await enrichWithCastleIOChallenge(challengeNames, challengeData);

        return dispatch(respondToChallenge(challengeData));
    },
);

export const respondToChallengeWithCode = createAsyncThunk(
    "reset-password/RESPOND_TO_CHALLENGE_CODE",
    async (code: string, { dispatch, getState }) => {
        const state = getState() as RootState;
        const { forgotPasswordSessionId, twoFAChallengeType, challengeNames } =
            state[resetPassword.name];

        const challengeName =
            twoFAChallengeType === MfaChallengeType.email
                ? ForgotPasswordChallengeType.email
                : ForgotPasswordChallengeType.phone;

        const challengeData: ForgotPasswordRespondToChallengeRequest = {
            sessionId: forgotPasswordSessionId,
            challenges: [
                {
                    challenge_name: challengeName,
                    challenge_response: { code },
                },
            ],
        };

        await enrichWithCastleIOChallenge(challengeNames, challengeData);

        return dispatch(respondToChallenge(challengeData));
    },
);

export const confirmForgotPassword = createAsyncThunk<ConfirmForgotPasswordResponse, string>(
    "reset-password/CONFIRM_FORGOT_PASSWORD",
    async (password, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const { forgotPasswordSessionId, redirectUrl } = state[resetPassword.name];

        const result = dispatch(
            authApi.endpoints.confirmForgotPassword.initiate({
                sessionId: forgotPasswordSessionId,
                password,
            }),
        );

        return result
            .unwrap()
            .then(() => {
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                }
                return {
                    isRedirect: !!redirectUrl,
                };
            })
            .catch((error) => rejectWithValue(error.data))
            .finally(result.reset);
    },
);

export const respondToChallenge = createAsyncThunk<
    RespondToChallengeAsyncThunkResponse,
    ForgotPasswordRespondToChallengeRequest,
    { rejectValue: ForgotPasswordError | TwoFAError }
>("reset-password/RESPOND_TO_CHALLENGE", async (args, { dispatch, rejectWithValue }) => {
    const result = dispatch(authApi.endpoints.respondToForgotPasswordChallenge.initiate(args));

    return result
        .unwrap()
        .then((data) => {
            let stage;
            let twoFAChallengeType = MfaChallengeType.phone;
            let twoFATitle = "";
            if (data.http_code === 200) {
                stage = ResetPasswordStage.PASSWORD;
            } else if (data?.challenges !== undefined) {
                const challengeName = data?.challenges[0]?.challenge_name;
                if (
                    [ForgotPasswordChallengeType.phone, ForgotPasswordChallengeType.email].includes(
                        challengeName,
                    )
                ) {
                    stage = ResetPasswordStage.TWO_FA;
                    if (challengeName === ForgotPasswordChallengeType.email) {
                        twoFAChallengeType = MfaChallengeType.email;
                        const parameters = data.challenges[0]
                            ?.challenge_parameters as ForgotPasswordMailChallengeParameters;
                        if (parameters && parameters.email) {
                            twoFATitle = parameters.email;
                        }
                    } else {
                        twoFAChallengeType = MfaChallengeType.phone;
                        const parameters = data.challenges[0]
                            ?.challenge_parameters as ForgotPasswordPhoneChallengeParameters;
                        if (parameters && parameters.phone_number) {
                            twoFATitle = parameters.phone_number;
                        }
                    }
                }
            }
            return {
                stage,
                twoFATitle,
                twoFAChallengeType,
                challengeNames:
                    data?.challenges?.map((challenge) => challenge.challenge_name) || [],
            };
        })
        .catch((error) => rejectWithValue(error.data))
        .finally(result.reset);
});

export const resendCode = createAsyncThunk<void, void, { rejectValue: TwoFAError }>(
    "reset-password/RESEND_CODE",
    async (args: void, { getState, dispatch, rejectWithValue }) => {
        const state = getState() as RootState;
        const { forgotPasswordSessionId, twoFAChallengeType } = state[resetPassword.name];

        const deliveryType =
            twoFAChallengeType === MfaChallengeType.email ? DeliveryType.email : DeliveryType.sms;

        const result = dispatch(
            authApi.endpoints.resendCodeForgotPasswordChallenge.initiate({
                sessionId: forgotPasswordSessionId,
                body: { delivery_type: deliveryType },
            }),
        );

        return result
            .unwrap()
            .then(() => undefined)
            .catch((error) => rejectWithValue(error))
            .finally(result.reset);
    },
);

export const resetPassword = createSlice({
    name: "resetPassword",
    initialState,
    reducers: {
        setInitCompleted(draft, { payload }: PayloadAction<boolean>) {
            draft.initCompleted = payload;
        },
        setLoading(draft, { payload }: PayloadAction<boolean>) {
            draft.isLoading = payload;
        },
        setAccountMethod(draft, { payload }: PayloadAction<AccountMethodType>) {
            draft.accountMethod = payload;
            draft.phone = "";
            draft.email = "";
            draft.isCaptchaError = false;
        },
        setStage(draft, { payload }: PayloadAction<ResetPasswordStage>) {
            draft.stage = payload;
        },
        resetCaptcha(draft) {
            draft.captchaChallenge = {} as GeetestData;
        },
        setRedirectUrl(draft, { payload }: PayloadAction<string>) {
            draft.redirectUrl = payload;
        },
        setCaptchaError(draft, { payload }: PayloadAction<boolean>) {
            draft.isCaptchaError = payload;
        },
        resetAllErrors(draft) {
            draft.businessErrorCode = undefined;
            draft.isCaptchaError = false;
            draft.twoFAError = undefined;
        },
        reset(draft) {
            const { initCompleted } = draft;
            return { ...initialState, initCompleted };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(appGetCountries.rejected, (draft) => {
            draft.initCompleted = true;
            draft.isError = true;
        });

        builder.addCase(getSettings.fulfilled, (draft) => {
            draft.initCompleted = true;
        });

        builder.addCase(getSettings.rejected, (draft) => {
            draft.initCompleted = true;
            draft.isError = true;
        });

        builder.addCase(forgotPassword.pending, (draft) => {
            draft.isLoading = true;
            draft.businessErrorCode = undefined;
            draft.isCaptchaError = false;
            draft.challengeNames = [];
        });

        builder.addCase(forgotPassword.fulfilled, (draft, { payload }) => {
            draft.isLoading = false;
            draft.forgotPasswordSessionId = payload.forgotPasswordSessionId;
            draft.captchaChallenge = payload.captchaChallenge;
            draft.phone = payload.phone;
            draft.challengeNames = payload.challengeNames;
        });

        builder.addCase(forgotPassword.rejected, (draft, { payload }) => {
            draft.isLoading = false;
            if (payload?.http_code === 422) {
                draft.businessErrorCode = payload?.code;
                draft.stage = errorReturnStageResetPassword(draft.businessErrorCode);
            } else {
                draft.isError = true;
            }
        });

        builder.addCase(respondToChallengeWithCaptcha.fulfilled, (draft) => {
            draft.captchaChallenge = {} as GeetestData;
        });

        builder.addCase(respondToChallenge.pending, (draft) => {
            draft.isLoading = true;
        });

        builder.addCase(respondToChallenge.fulfilled, (draft, { payload }) => {
            draft.isLoading = false;
            draft.twoFATitle = payload.twoFATitle;
            draft.twoFAChallengeType = payload.twoFAChallengeType;
            draft.challengeNames = payload.challengeNames;
            if (payload.stage) {
                draft.stage = payload.stage;
            }
        });

        builder.addCase(respondToChallenge.rejected, (draft, { payload }) => {
            if (payload?.code === TwoFAErrorCode.WRONG_CODE) {
                const twoFAError = payload as TwoFAError;
                if (twoFAError?.remaining_attempts_count > 0) {
                    draft.twoFAError = twoFAError;
                    draft.stage = errorReturnStageResetPassword(draft.twoFAError?.code);
                } else {
                    draft.twoFAError = undefined;
                    draft.businessErrorCode = BusinessErrorCode.TOO_MANY_CHALLENGE_FAILURES;
                    draft.stage = errorReturnStageResetPassword(draft.businessErrorCode);
                }
            } else {
                const error = payload as ForgotPasswordError;
                draft.businessErrorCode = error?.code;
                draft.twoFAError = undefined;
                draft.stage = errorReturnStageResetPassword(draft.businessErrorCode);
            }
            draft.isLoading = false;
        });

        builder.addCase(confirmForgotPassword.pending, (draft) => {
            draft.isLoading = true;
            draft.businessErrorCode = undefined;
        });

        builder.addCase(confirmForgotPassword.fulfilled, (draft, { payload }) => {
            if (!payload.isRedirect) {
                draft.isLoading = false;
                draft.isPasswordSuccessfullyChanged = true;
            }
        });

        builder.addCase(confirmForgotPassword.rejected, (draft, { payload }) => {
            draft.isLoading = false;
            const error = payload as ForgotPasswordError;
            if (
                error?.http_code === 422 &&
                error?.code === BusinessErrorCode.REQUEST_IS_INCORRECT
            ) {
                draft.businessErrorCode = BusinessErrorCode.PASSWORD_TOO_SIMPLE;
                draft.stage = errorReturnStageResetPassword(draft.businessErrorCode);
            } else {
                draft.isError = true;
            }
        });

        builder.addCase(resendCode.fulfilled, (draft) => {
            draft.isLoading = false;
        });

        builder.addCase(resendCode.rejected, (draft, { payload }) => {
            draft.twoFAError = payload;
            draft.isLoading = false;
        });

        builder.addCase(resendCode.pending, (draft) => {
            draft.isLoading = true;
            draft.twoFAError = undefined;
        });
    },
});

export const {
    setInitCompleted,
    setLoading,
    setAccountMethod,
    setStage,
    resetAllErrors,
    reset,
    resetCaptcha,
    setRedirectUrl,
    setCaptchaError,
} = resetPassword.actions;
