export enum SearchParamNames {
    CLIENT_ID = "client_id",
    EMAIL = "email",
    NEXT = "next",
    PHONE = "phone",
    PHONE_CODE = "phone_code",
    PHONE_NUMBER = "phone_number",
    REDIRECT_URL = "redirect_url",
    REDIRECT_URI = "redirect_uri",
    REFERRAL_CODE = "r",
    STATE = "state",
    LOCALE = "locale",
    CODE = "code",
    ERROR = "error",
}
