import { BusinessErrorCode, TwoFAErrorCode } from "../services/types";
import { ResetPasswordStage } from "../store/reset-password/reset-password";
import { LogInStage } from "../store/signin/signin";
import { RegisterStage } from "../store/signup/signup";

export const errorReturnStageSignIn = (code?: string) => {
    switch (code) {
        case BusinessErrorCode.INVALID_LOGIN_PASSWORD:
        case BusinessErrorCode.INVALID_GOOGLE_LOGIN:
        case BusinessErrorCode.INVALID_FACEBOOK_LOGIN:
        case BusinessErrorCode.PHONE_AUTH_NOT_ALLOWED:
        case BusinessErrorCode.OAUTH_INVALID_CODE:
        case BusinessErrorCode.SOCIAL_MEDIA_ERROR:
        case BusinessErrorCode.MFA_METHOD_NOT_CONFIGURED:
        case BusinessErrorCode.TOO_MANY_CHALLENGE_FAILURES:
            return LogInStage.DATA;
        case TwoFAErrorCode.WRONG_CODE:
        case TwoFAErrorCode.TOO_MANY_REQUESTS:
            return LogInStage.TWO_FA;
        default:
            return LogInStage.DATA;
    }
};

export const errorReturnStageSignUp = (code?: string) => {
    switch (code) {
        case BusinessErrorCode.REQUEST_IS_INCORRECT:
        case BusinessErrorCode.INCORRECT_EMAIL:
        case BusinessErrorCode.INCORRECT_PHONE:
        case BusinessErrorCode.EMAIL_CANNOT_CONTAIN_ALIAS:
        case BusinessErrorCode.PHONE_COUNTRY_NOT_ALLOWED:
        case BusinessErrorCode.EMAIL_BLACKLISTED:
        case BusinessErrorCode.USER_ALREADY_EXISTS:
            return RegisterStage.DATA;
        case BusinessErrorCode.PASSWORD_TOO_SIMPLE:
        case BusinessErrorCode.TOO_MANY_CHALLENGE_FAILURES:
            return RegisterStage.DATA;
        case TwoFAErrorCode.WRONG_CODE:
        case TwoFAErrorCode.TOO_MANY_REQUESTS:
            return RegisterStage.TWO_FA;
        default:
            return RegisterStage.DATA;
    }
};

export const errorReturnStageResetPassword = (code?: string) => {
    switch (code) {
        case BusinessErrorCode.REQUEST_IS_INCORRECT:
        case BusinessErrorCode.TOO_MANY_CHALLENGE_FAILURES:
            return ResetPasswordStage.DATA;
        case TwoFAErrorCode.WRONG_CODE:
        case TwoFAErrorCode.TOO_MANY_REQUESTS:
            return ResetPasswordStage.TWO_FA;
        case BusinessErrorCode.PASSWORD_TOO_SIMPLE:
            return ResetPasswordStage.DATA;
        default:
            return ResetPasswordStage.DATA;
    }
};
