import React from "react";

import { Flex, FlexProps, Text } from "@noones/ui-kit";

export type LabeledControlProps = FlexProps & {
    label: string;
};

export const LabeledControl = ({ label, children, ...props }: LabeledControlProps) => (
    <Flex direction="column" gap={3} align="flex-start" w="100%" {...props}>
        <Flex pt={4} pb={4}>
            <Text size="headlineMSemibold">{label}</Text>
        </Flex>

        <Flex pt={8} pb={8} gap={8} w="100%">
            {children}
        </Flex>
    </Flex>
);
