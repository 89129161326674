import React, { Component, ComponentType, ErrorInfo, ReactNode } from "react";

export type ErrorBoundaryProps = {
    onError: (error: unknown, errorInfo: ErrorInfo) => void;
    fallback?: ComponentType<{ error: unknown }>;
    children: ReactNode;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, { error: unknown | null }> {
    state = { error: null };

    componentDidCatch(error: unknown, errorInfo: ErrorInfo) {
        this.props.onError(error, errorInfo);
        this.setState({ error });
    }

    render() {
        if (!this.state.error) {
            return <>{this.props.children}</>;
        }

        if (this.props.fallback) {
            //@ts-ignore
            return <this.props.fallback error={this.state.error} />;
        }

        return null;
    }
}
