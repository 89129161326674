import { getCookie } from "lib/cookies";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const profileApi = createApi({
    reducerPath: "profileApi",
    tagTypes: ["User"],

    baseQuery: fetchBaseQuery({
        baseUrl: "/id/public/",
        prepareHeaders: (headers) => {
            const csrftoken = getCookie("csrftoken");
            if (csrftoken) headers.set("X-CSRFToken", csrftoken);

            return headers;
        },
    }),
    endpoints: () => ({}),
});
