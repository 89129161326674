import { Country as CountryPhone } from "@noones/ui-kit";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppDispatch } from "../../app/store/store";
import { writeLocaleToStorage } from "../../lib/storage";
import { authApi } from "../../services/authApi";
import { Country, SettingsParamsResponse } from "../../services/types";

export type GetCountriesResponse = {
    countries: CountryPhone[];
    codes: Record<string, string>;
    complianceGroup: Record<string, number>;
};

export type Config = {
    accountsAuthorizeUrl: string;
    marketplaceUrl: string;
    marketplaceClientId: string;
    signupEnabled: boolean;
    signupByPhoneEnabled: boolean;
    signupByEmailEnabled: boolean;
    waitingListUrl: string;
    supportUrl: string;
    complexRegulationCountries: string[];
    gamificationAwardsEnabled: boolean;
    escrowFeeDiscountEnabled: boolean;
    signupByGoogleEnabled: boolean;
    signupByFacebookEnabled: boolean;
    signupByAppleEnabled: boolean;
    googleRegisterUrl: string;
    googleLoginUrl: string;
    facebookRegisterUrl: string;
    facebookLoginUrl: string;
    appleRegisterUrl: string;
    appleLoginUrl: string;
};

export type RegistrationInfo = {
    citizenshipConsentRequired: boolean;
    ipAddress: string;
    ipCountry: string;
};

export type AppState = {
    locale: string;
    countries: CountryPhone[];
    codes: Record<string, string>;
    complianceGroup: Record<string, number>;
    config: Config;
    registrationInfo: RegistrationInfo;
};

export const changeAppLocale = (locale: string) => (dispatch: AppDispatch) => {
    dispatch(app.actions.setLocale(locale));
    writeLocaleToStorage(locale);
};

const initialState: AppState = {
    locale: "",
    countries: [],
    codes: {},
    complianceGroup: {},
    config: {
        accountsAuthorizeUrl: "",
        marketplaceUrl: "",
        marketplaceClientId: "",
        signupEnabled: false,
        signupByPhoneEnabled: false,
        signupByEmailEnabled: false,
        waitingListUrl: "",
        supportUrl: "",
        complexRegulationCountries: [],
        gamificationAwardsEnabled: false,
        escrowFeeDiscountEnabled: false,
        signupByGoogleEnabled: false,
        signupByFacebookEnabled: false,
        signupByAppleEnabled: false,
        googleRegisterUrl: "",
        googleLoginUrl: "",
        facebookRegisterUrl: "",
        facebookLoginUrl: "",
        appleRegisterUrl: "",
        appleLoginUrl: "",
    },
    registrationInfo: {
        citizenshipConsentRequired: false,
        ipAddress: "",
        ipCountry: "",
    },
};

export const appGetCountries = createAsyncThunk<GetCountriesResponse, void>(
    "app/GET_COUNTRIES",
    async (args, { dispatch, rejectWithValue }) => {
        const result = dispatch(authApi.endpoints.countries.initiate());

        return result
            .unwrap()
            .then((countries) => {
                const getCodes = (arr: Country[]): { [key: string]: string } => {
                    if (arr.length === 0) {
                        return {};
                    }
                    return arr.reduce((result, country) => {
                        const { iso, phoneCode } = country;
                        result[iso] = phoneCode.toString();
                        return result;
                    }, {} as { [key: string]: string });
                };

                const getComplianceGroup = (arr: Country[]): { [key: string]: number } => {
                    if (arr.length === 0) {
                        return {};
                    }
                    return arr.reduce((result, country) => {
                        const { iso, complianceGroup } = country;
                        result[iso] = complianceGroup || 0;
                        return result;
                    }, {} as { [key: string]: number });
                };

                const availableCountries = countries.filter(
                    (country) => country.complianceGroup !== 0,
                );

                return {
                    countries: availableCountries.map(({ iso, name }) => ({
                        iso: iso,
                        localizedName: name,
                        name: name,
                    })),
                    codes: getCodes(availableCountries),
                    complianceGroup: getComplianceGroup(availableCountries),
                };
            })
            .catch((error) => rejectWithValue(error.data))
            .finally(result.unsubscribe);
    },
);

export const getSettings = createAsyncThunk<SettingsParamsResponse, void>(
    "app/GET_SETTINGS",
    async (args, { dispatch, rejectWithValue }) => {
        const result = dispatch(authApi.endpoints.settings.initiate());

        return result
            .unwrap()
            .then((data) => data)
            .catch((error) => rejectWithValue(error.data))
            .finally(result.reset);
    },
);

export const app = createSlice({
    name: "app",
    initialState,
    reducers: {
        setLocale(draft, { payload }: PayloadAction<string>) {
            draft.locale = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(appGetCountries.fulfilled, (draft, { payload }) => {
            draft.countries = payload.countries;
            draft.codes = payload.codes;
            draft.complianceGroup = payload.complianceGroup;
        });
        builder.addCase(getSettings.fulfilled, (draft, { payload }) => {
            const { config, registration_info } = payload;

            const {
                accounts_authorize_url: accountsAuthorizeUrl,
                marketplace_url: marketplaceUrl,
                marketplace_client_id: marketplaceClientId,
                signup_enabled: signupEnabled,
                signup_by_phone_enabled: signupByPhoneEnabled,
                signup_by_email_enabled: signupByEmailEnabled,
                waiting_list_url: waitingListUrl,
                support_url: supportUrl,
                complex_regulation_countries: complexRegulationCountries,
                gamification_awards_enabled: gamificationAwardsEnabled,
                escrow_fee_discount_enabled: escrowFeeDiscountEnabled,
                signup_by_google_enabled: signupByGoogleEnabled,
                signup_by_facebook_enabled: signupByFacebookEnabled,
                signup_by_apple_enabled: signupByAppleEnabled,
                google_register_url: googleRegisterUrl,
                google_login_url: googleLoginUrl,
                facebook_register_url: facebookRegisterUrl,
                facebook_login_url: facebookLoginUrl,
                apple_register_url: appleRegisterUrl,
                apple_login_url: appleLoginUrl,
            } = config;

            const {
                citizenship_consent_required: citizenshipConsentRequired,
                ip_address: ipAddress,
                ip_country: ipCountry,
            } = registration_info;

            draft.config = {
                accountsAuthorizeUrl,
                marketplaceUrl,
                marketplaceClientId,
                signupEnabled,
                signupByPhoneEnabled,
                signupByEmailEnabled,
                waitingListUrl,
                supportUrl,
                complexRegulationCountries,
                gamificationAwardsEnabled,
                escrowFeeDiscountEnabled,
                signupByGoogleEnabled,
                signupByFacebookEnabled,
                signupByAppleEnabled,
                googleRegisterUrl,
                googleLoginUrl,
                facebookRegisterUrl,
                facebookLoginUrl,
                appleRegisterUrl,
                appleLoginUrl,
            };

            draft.registrationInfo = {
                citizenshipConsentRequired,
                ipAddress,
                ipCountry,
            };
        });
    },
});

export const { setLocale } = app.actions;
