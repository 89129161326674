import React from "react";
import { Helmet } from "react-helmet-async";

export type HeadTitleProps = {
    title: string;
};

export const HeadTitle = ({ title }: HeadTitleProps) => (
    //@ts-ignore
    <Helmet>
        <title>{title}</title>
    </Helmet>
);
