import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { authApi } from "../../services/authApi";
import {
    PutSecurityQuestionsArg,
    ResetTwoFAError,
    ResetTwoFAErrorCode,
    TwoFAError,
    TwoFAErrorCode,
} from "../../services/types";
import { appGetCountries, getSettings } from "../app/app";

export type Reset2FAState = {
    initCompleted: boolean;
    isError: boolean;
    isLoading: boolean;
    successModalOpen: boolean;
    error?: ResetTwoFAError;
};

const initialState: Reset2FAState = {
    initCompleted: false,
    isError: false,
    isLoading: false,
    successModalOpen: false,
    error: undefined,
};

type SubmitSecurityQuestionsArg = PutSecurityQuestionsArg;

export const putSecurityQuestions = createAsyncThunk<
    void,
    SubmitSecurityQuestionsArg,
    { rejectValue: ResetTwoFAError }
>("reset-2fa/PUT_SECURITY_QUESTIONS", async (args, { dispatch, rejectWithValue }) => {
    const { phone, ...other } = args;
    const result = dispatch(
        authApi.endpoints.putSecurityQuestions.initiate({
            ...other,
            ...(phone && {
                phone_number: phone,
            }),
        }),
    );

    return result
        .unwrap()
        .catch((error) => rejectWithValue(error.data))
        .finally(result.reset);
});

export const reset2FA = createSlice({
    name: "reset-2fa",
    initialState,
    reducers: {
        setSuccessModalVisible(draft, { payload }) {
            draft.successModalOpen = payload;
        },
        reset(draft) {
            const { initCompleted } = draft;
            return { ...initialState, initCompleted };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(appGetCountries.fulfilled, (draft) => {
            draft.initCompleted = true;
        });

        builder.addCase(appGetCountries.rejected, (draft) => {
            draft.initCompleted = true;
            draft.isError = true;
        });

        builder.addCase(getSettings.fulfilled, (draft) => {
            draft.initCompleted = true;
        });

        builder.addCase(getSettings.rejected, (draft) => {
            draft.initCompleted = true;
            draft.isError = true;
        });

        builder.addCase(putSecurityQuestions.pending, (draft) => {
            draft.isLoading = true;
            draft.successModalOpen = false;
            draft.error = undefined;
        });

        builder.addCase(putSecurityQuestions.fulfilled, (draft) => {
            draft.isLoading = false;
            draft.successModalOpen = true;
        });

        builder.addCase(putSecurityQuestions.rejected, (draft, { payload }) => {
            const twoFAError = payload as TwoFAError;
            if (
                twoFAError?.code === ResetTwoFAErrorCode.WRONG_ANSWER &&
                twoFAError?.remaining_attempts_count === 0
            ) {
                draft.error = { code: TwoFAErrorCode.LINK_EXPIRED, message: "" };
            } else {
                draft.error = payload;
            }
            draft.isLoading = false;
        });
    },
});

export const { reset, setSuccessModalVisible } = reset2FA.actions;
