import React, { ErrorInfo } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";

import { ThemeProvider as ThemeProviderKit } from "@noones/ui-kit";

import { capture, initSentry } from "../services/sentry";
import { Profile } from "./Profile";
import { ErrorBoundary } from "./provider/ErrorBoundary";
import { store } from "./store/store";

const observer = new ResizeObserver((entries) => {
    const entry = entries[entries.length - 1];
    window.parent.postMessage(
        JSON.stringify({
            channel: "wallet-x",
            type: "resize",
            height: entry.contentRect.height,
        }),
        "*",
    );
});

observer.observe(document.documentElement);

export const App = () => {
    const localTheme = localStorage.getItem("profileTheme") as "dark" | "light";

    if (window.appSentryDsn) {
        initSentry(window.appSentryDsn);
    }

    const handleError = (error: unknown, errorInfo: ErrorInfo) => {
        capture({
            error,
            transactionName: "ErrorBoundary",
            fingerprint: [
                "react",
                "global",
                errorInfo.componentStack,
                error instanceof Error ? error.name : "unknown",
                error instanceof Error ? error.message : "unknown",
            ],
            tags: { ui: true, "ui-branch": "beta" },
            context: { info: { componentStack: errorInfo.componentStack } },
        });
    };

    return (
        <ErrorBoundary onError={handleError}>
            <ThemeProviderKit theme={localTheme || "light"}>
                <Provider store={store}>
                    {/* @ts-ignore */}
                    <HelmetProvider>
                        <Profile />
                    </HelmetProvider>
                </Provider>
            </ThemeProviderKit>
        </ErrorBoundary>
    );
};
