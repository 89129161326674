import React, { ReactNode, useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";

import Typed from "typed.js";

import { Box, Card, Flex, Text } from "@noones/ui-kit";

export type AnswerTailPosition = "left" | "bottom";

export type AnswerProps = {
    isMobile?: boolean;
    text: ReactNode;
    tailPosition?: AnswerTailPosition;
    children?: ReactNode;
};

export const Answer = ({
    isMobile = false,
    text,
    tailPosition = "left",
    children,
}: AnswerProps) => {
    const el = useRef<HTMLDivElement>(null);
    const typed = React.useRef<Typed | null>(null);

    useEffect(() => {
        const options = {
            strings: [renderToString(<>{text}</>)],
            typeSpeed: 50,
            showCursor: false,
        };

        if (el.current) {
            typed.current = new Typed(el.current, options);
        }

        return () => {
            typed.current?.destroy();
        };
    }, [text]);

    const tail =
        tailPosition === "left" ? (
            <>
                <Box bg="backgroundFixedDefault" w={6} h={6} />
                <Box bg="backgroundFixedDefault" w={6} h={18} />
                <Box bg="backgroundFixedDefault" w={6} h={30} />
            </>
        ) : (
            <Flex ml={75}>
                <Flex direction="column" align="flex-end">
                    <Box bg="backgroundFixedDefault" w={18} h={6} />
                    <Box bg="backgroundFixedDefault" w={12} h={6} />
                    <Box bg="backgroundFixedDefault" w={6} h={6} />
                </Flex>
            </Flex>
        );

    return (
        <Flex direction="column" maw={261}>
            <Flex align="center">
                {tailPosition === "left" && tail}

                <Card
                    edge={6}
                    pt={8}
                    px={18}
                    pb={12}
                    bg="backgroundFixedDefault"
                    w={isMobile ? 202 : 307}
                >
                    <Flex direction="column" gap={16}>
                        <Box mih={60} w="100%">
                            <Text size="headlineMMediumMono" c="textFixedDark">
                                <span ref={el} />
                            </Text>
                        </Box>

                        {children}
                    </Flex>
                </Card>
            </Flex>

            {tailPosition === "bottom" && tail}
        </Flex>
    );
};
