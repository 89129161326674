import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../app/store/store";
import { AccountMethodType } from "../../features/auth/account/form/controls/AccountMethod";
import { RegisterFormData } from "../../features/auth/account/form/SignUpForm";
import { GeetestSuccessResponse } from "../../features/geetest/geetest";
import { GeetestData } from "../../features/geetest/GeetestData";
import { castle } from "../../lib/castle_io";
import { errorReturnStageSignUp } from "../../lib/returnStage";
import {
    FeatureFlag,
    isFeatureFlagSet,
    readFromStorage,
    removeFromStorage,
    StorageKeys,
    writeToStorage,
} from "../../lib/storage";
import { authApi } from "../../services/authApi";
import { registrationEvent } from "../../services/streamer";
import {
    BusinessErrorCode,
    CaptchaChallengeParameters,
    CaptchaChallengeResponse,
    DeliveryType,
    MfaChallengeType,
    RegisterChallengeType,
    RegisterRespondToChallengeRequest,
    TwoFAError,
    TwoFAErrorCode,
} from "../../services/types";
import { appGetCountries, getSettings } from "../app/app";

export enum RegisterStage {
    DATA = 0,
    TWO_FA = 1,
}

export type SignUpInitialState = {
    initCompleted: boolean;
    isLoading: boolean;
    phone: string;
    email: string;
    accountMethod: AccountMethodType;
    stage: RegisterStage;
    twoFATitle: string;
    twoFAChallengeType: MfaChallengeType;
    captchaChallenge: GeetestData;
    registerSessionId: string;
    isCaptchaError: boolean;
    isError: boolean;
    twoFAError?: TwoFAError;
    businessErrorCode?: string;
    businessWarningCode?: string;
    challengeNames: Array<RegisterChallengeType>;
    lastUsername: string;
    username: string;
    isSuccessRegister: boolean;
};

const initialState: SignUpInitialState = {
    initCompleted: false,
    isLoading: false,
    phone: "",
    email: "",
    accountMethod: AccountMethodType.EMAIL,
    stage: RegisterStage.DATA,
    twoFATitle: "",
    twoFAChallengeType: MfaChallengeType.phone,
    captchaChallenge: {} as GeetestData,
    registerSessionId: "",
    isCaptchaError: false,
    isError: false,
    twoFAError: undefined,
    businessErrorCode: undefined,
    businessWarningCode: undefined,
    challengeNames: [],
    lastUsername: "",
    username: "",
    isSuccessRegister: false,
};

export type RegisterUserResponse = {
    twoFATitle: string;
    twoFAChallengeType: MfaChallengeType;
    registerSessionId: string;
    captchaChallenge: GeetestData;
    stage?: RegisterStage;
    challengeNames: Array<RegisterChallengeType>;
    email?: string;
    phone?: string;
};

export type RespondToChallengeWithCodeArg = {
    code: string;
};

export type RespondToRegisterChallengeResponse = {
    stage?: RegisterStage;
    challengeNames: Array<RegisterChallengeType>;
    lastUsername: string;
    isContinueLoading: boolean;
};

export type RegisterPatchProfileArg = {
    username?: string;
    referralCode?: string;
};

export type RegisterError = {
    code: string;
    message: string;
    http_code: number;
};

export const init = createAsyncThunk("signup/INIT", async (arg, { dispatch, getState }) => {
    const loginToken = readFromStorage(StorageKeys.LOGIN_TOKEN);
    if (loginToken) {
        dispatch(logIn());
        return;
    }

    await dispatch(appGetCountries());

    await dispatch(getSettings());

    const stateStore = getState() as RootState;
    const { config } = stateStore.app;
    const {
        marketplaceUrl,
        marketplaceClientId,
        accountsAuthorizeUrl,
        signupByEmailEnabled,
        signupByPhoneEnabled,
    } = config;

    writeToStorage(
        StorageKeys.REDIRECT_URL,
        readFromStorage(StorageKeys.REDIRECT_URL) || marketplaceUrl,
    );
    writeToStorage(
        StorageKeys.CLIENT_ID,
        readFromStorage(StorageKeys.CLIENT_ID) || marketplaceClientId,
    );
    writeToStorage(StorageKeys.ACCOUNTS_AUTHORIZE_URL, accountsAuthorizeUrl);

    const registerSessionId = readFromStorage(StorageKeys.SESSION_ID);
    if (registerSessionId) {
        const lastUsername = readFromStorage(StorageKeys.LAST_USERNAME);
        removeFromStorage(StorageKeys.SESSION_ID);
        removeFromStorage(StorageKeys.LAST_USERNAME);
        if (isFeatureFlagSet(FeatureFlag.USERNAME_SKIP)) {
            dispatch(logIn());
        } else {
            dispatch(setSocialMediaRegistrationSuccessful({ registerSessionId, lastUsername }));
        }
    } else {
        const email = readFromStorage(StorageKeys.EMAIL);
        const phone = readFromStorage(StorageKeys.PHONE);

        if (email && signupByEmailEnabled) {
            dispatch(setAccountMethod(AccountMethodType.EMAIL));
            dispatch(signUp.actions.setEmail(email));
        } else if (phone && signupByPhoneEnabled) {
            dispatch(setAccountMethod(AccountMethodType.PHONE));
            dispatch(signUp.actions.setPhone(phone));
        } else if (!signupByEmailEnabled && signupByPhoneEnabled) {
            dispatch(setAccountMethod(AccountMethodType.PHONE));
        }
    }
});

export const registerUser = createAsyncThunk<
    RegisterUserResponse,
    RegisterFormData,
    { rejectValue: RegisterError }
>("signup/REGISTER_USER", async (args, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { locale } = state.app;
    const refCode = readFromStorage(StorageKeys.REFERRAL_CODE);
    const isNotCitizen =
        readFromStorage(StorageKeys.IS_NOT_CITIZEN_ACCEPTED)?.toLowerCase() === "true";
    const result = dispatch(
        authApi.endpoints.register.initiate({
            ...(args.phone && { phone: args.phone }),
            ...(args.email && { email: args.email }),
            ...(isNotCitizen && { citizenship_consent_accepted: isNotCitizen }),
            ...(refCode && { referral_code: refCode }),
            password: args.password,
            locale: locale,
            platform: "web",
            client_id: readFromStorage(StorageKeys.CLIENT_ID),
            app_version: "0.0.1",
            device_id: window.navigator.userAgent,
        }),
    );

    return result
        .unwrap()
        .then((data) => {
            let captcha = {} as GeetestData;
            let stage;
            const { challenges, register_session_id: registerSessionId } = data;

            if (challenges) {
                const challenge = challenges[0];

                const captchaChallenge = challenges.filter(
                    (challenge) => challenge?.challenge_name === RegisterChallengeType.geetest,
                )[0];
                if (captchaChallenge) {
                    const geetestParams =
                        captchaChallenge.challenge_parameters as CaptchaChallengeParameters;
                    captcha = {
                        captchaId: geetestParams.captcha_id,
                        challenge: geetestParams.challenge,
                        newCaptcha: true,
                        offline: geetestParams.offline,
                        lang: "en",
                        hideClose: true,
                    };
                } else if (
                    [RegisterChallengeType.phone, RegisterChallengeType.email].includes(
                        challenge?.challenge_name,
                    )
                ) {
                    stage = RegisterStage.TWO_FA;
                }
            }

            if (data.http_code === 200) {
                removeFromStorage(StorageKeys.REFERRAL_CODE);
            }

            return {
                twoFATitle: args.email || args.phone,
                twoFAChallengeType: args.email ? MfaChallengeType.email : MfaChallengeType.phone,
                registerSessionId: registerSessionId || "",
                captchaChallenge: captcha,
                stage,
                challengeNames: challenges.map((challenge) => challenge.challenge_name),
                email: args.email,
                phone: args.phone,
            };
        })
        .catch((error) => rejectWithValue(error.data))
        .finally(result.reset);
});

export const enrichWithCastleIOChallenge = async (
    challengeNames: Array<RegisterChallengeType>,
    challengeData: RegisterRespondToChallengeRequest,
) => {
    if (challengeNames.includes(RegisterChallengeType.castleio)) {
        const castleIORequestToken = await castle.createRequestToken();
        challengeData.challenges.push({
            challenge_name: RegisterChallengeType.castleio,
            challenge_response: {
                castleio_request_token: castleIORequestToken,
            },
        });
    }
};

export const respondToRegisterChallenge = createAsyncThunk<
    RespondToRegisterChallengeResponse,
    RegisterRespondToChallengeRequest,
    { rejectValue: RegisterError | TwoFAError }
>("signup/RESPOND_TO_REGISTER_CHALLENGE", async (args, { dispatch, getState, rejectWithValue }) => {
    const result = dispatch(authApi.endpoints.respondToRegisterChallenge.initiate(args));

    return result
        .unwrap()
        .then((data) => {
            let stage;
            let lastUsername = "";
            let isContinueLoading = false;
            if (data.http_code === 200) {
                writeToStorage(StorageKeys.LOGIN_TOKEN, data?.auth_result?.login_token);
                removeFromStorage(StorageKeys.REFERRAL_CODE);
                lastUsername = data?.username || "";
                const state = getState() as RootState;
                const { email } = state[signUp.name];
                registrationEvent(email ? "email" : "phone");
                isContinueLoading = true;
                dispatch(logIn());
            } else if (data?.challenges !== undefined) {
                const challenge = data?.challenges[0];
                if (
                    challenge?.challenge_name === RegisterChallengeType.phone ||
                    challenge?.challenge_name === RegisterChallengeType.email
                ) {
                    stage = RegisterStage.TWO_FA;
                }
            }

            return {
                stage,
                challengeNames:
                    data?.challenges?.map((challenge) => challenge.challenge_name) || [],
                lastUsername,
                isContinueLoading,
            };
        })
        .catch((error) => rejectWithValue(error.data))
        .finally(result.reset);
});

export const respondToChallengeWithCode = createAsyncThunk(
    "signup/RESPOND_TO_REGISTER_CODE",
    async (args: RespondToChallengeWithCodeArg, { dispatch, getState }) => {
        const state = getState() as RootState;
        const { registerSessionId, twoFAChallengeType, challengeNames } = state[signUp.name];

        const challengeName =
            twoFAChallengeType === MfaChallengeType.email
                ? RegisterChallengeType.email
                : RegisterChallengeType.phone;

        const challengeData: RegisterRespondToChallengeRequest = {
            registerSessionId,
            challenges: [
                {
                    challenge_name: challengeName,
                    challenge_response: { code: args.code },
                },
            ],
        };

        await enrichWithCastleIOChallenge(challengeNames, challengeData);

        return dispatch(respondToRegisterChallenge(challengeData));
    },
);

export const respondToChallengeWithCaptcha = createAsyncThunk(
    "signup/RESPOND_TO_REGISTER_CAPTCHA",
    async (args: GeetestSuccessResponse, { dispatch, getState }) => {
        const state = getState() as RootState;
        const { registerSessionId, captchaChallenge, challengeNames } = state[signUp.name];
        const challengeResponse: CaptchaChallengeResponse = {
            challenge: captchaChallenge.challenge,
            seccode: args.geetest_seccode,
            validate: args.geetest_validate,
        };

        const challengeData: RegisterRespondToChallengeRequest = {
            registerSessionId,
            challenges: [
                {
                    challenge_name: RegisterChallengeType.geetest,
                    challenge_response: challengeResponse,
                },
            ],
        };

        await enrichWithCastleIOChallenge(challengeNames, challengeData);

        return dispatch(respondToRegisterChallenge(challengeData));
    },
);

export const patchProfile = createAsyncThunk<
    string,
    RegisterPatchProfileArg,
    { rejectValue: RegisterError }
>("signup/PROFILE_PATCH", async (args, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { registerSessionId } = state[signUp.name];

    const result = dispatch(
        authApi.endpoints.registerPatchProfile.initiate({
            registerSessionId,
            body: {
                ...(args.username && { username: args.username }),
                ...(args.referralCode && { referral_code: args.referralCode }),
            },
        }),
    );

    return result
        .unwrap()
        .then((data) => data.username)
        .catch((error) => rejectWithValue(error.data))
        .finally(result.reset);
});

export const registerResendCode = createAsyncThunk<void, void, { rejectValue: TwoFAError }>(
    "signup/RESEND_CODE",
    async (args: void, { getState, dispatch, rejectWithValue }) => {
        const state = getState() as RootState;
        const { registerSessionId, twoFAChallengeType } = state[signUp.name];

        const deliveryType =
            twoFAChallengeType === MfaChallengeType.email ? DeliveryType.email : DeliveryType.sms;

        const result = dispatch(
            authApi.endpoints.resendCode.initiate({
                sessionId: registerSessionId,
                body: { delivery_type: deliveryType },
            }),
        );

        return result
            .unwrap()
            .then((data) => {
                data.register_session_id;
            })
            .catch((error) => rejectWithValue(error))
            .finally(result.reset);
    },
);

export const signUp = createSlice({
    name: "signUp",
    initialState,
    reducers: {
        setInitCompleted(draft, { payload }: PayloadAction<boolean>) {
            draft.initCompleted = payload;
        },
        setPhone(draft, { payload }: PayloadAction<string>) {
            draft.phone = payload;
        },
        setEmail(draft, { payload }: PayloadAction<string>) {
            draft.email = payload;
        },
        setAccountMethod(draft, { payload }: PayloadAction<AccountMethodType>) {
            draft.accountMethod = payload;
            draft.phone = "";
            draft.email = "";
            draft.isCaptchaError = false;
        },
        setStage(draft, { payload }: PayloadAction<RegisterStage>) {
            draft.stage = payload;
        },
        setLoading(draft, { payload }: PayloadAction<boolean>) {
            draft.isLoading = payload;
        },
        resetCaptcha(draft) {
            draft.captchaChallenge = {} as GeetestData;
        },
        setCaptchaError(draft, { payload }: PayloadAction<boolean>) {
            draft.isCaptchaError = payload;
        },
        setBusinessErrorCode(draft, { payload }: PayloadAction<BusinessErrorCode | undefined>) {
            draft.businessErrorCode = payload && payload.valueOf();
        },
        resetAllErrors(draft) {
            draft.businessErrorCode = undefined;
            draft.businessWarningCode = undefined;
            draft.isCaptchaError = false;
            draft.twoFAError = undefined;
        },
        setSuccessRegister(draft, { payload }: PayloadAction<boolean>) {
            draft.isSuccessRegister = payload;
        },
        setSocialMediaRegistrationSuccessful(
            draft,
            { payload }: PayloadAction<{ lastUsername: string; registerSessionId: string }>,
        ) {
            draft.lastUsername = payload.lastUsername;
            draft.registerSessionId = payload.registerSessionId;
        },
        logIn(draft) {
            draft.isLoading = true;
            draft.isSuccessRegister = false;
            const loginToken = readFromStorage(StorageKeys.LOGIN_TOKEN);
            removeFromStorage(StorageKeys.LOGIN_TOKEN);
            const accountsAuthorizeURL = readFromStorage(StorageKeys.ACCOUNTS_AUTHORIZE_URL);
            const clientId = readFromStorage(StorageKeys.CLIENT_ID);
            const redirectUri = readFromStorage(StorageKeys.REDIRECT_URL);
            const state = readFromStorage(StorageKeys.STATE);
            window.location.href = `${accountsAuthorizeURL}?client_id=${clientId}&login_token=${loginToken}&redirect_uri=${encodeURIComponent(
                redirectUri,
            )}&state=${state}&response_type=code`;
        },
        reset(draft) {
            const { accountMethod, initCompleted } = draft;
            return {
                ...initialState,
                accountMethod,
                initCompleted,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(appGetCountries.rejected, (draft) => {
            draft.initCompleted = true;
            draft.isError = true;
        });

        builder.addCase(getSettings.fulfilled, (draft) => {
            draft.initCompleted = true;
        });
        builder.addCase(getSettings.rejected, (draft) => {
            draft.initCompleted = true;
            draft.isError = true;
        });

        builder.addCase(registerUser.pending, (draft) => {
            draft.isLoading = true;
            draft.challengeNames = [];
        });

        builder.addCase(
            registerUser.fulfilled,
            (draft, { payload }: PayloadAction<RegisterUserResponse>) => {
                draft.isLoading = false;
                draft.twoFATitle = payload.twoFATitle;
                draft.twoFAChallengeType = payload.twoFAChallengeType;
                draft.registerSessionId = payload.registerSessionId;
                draft.captchaChallenge = payload.captchaChallenge;
                draft.challengeNames = payload.challengeNames;
                draft.phone = payload.phone || "";
                draft.email = payload.email || "";
                if (payload.stage) {
                    draft.stage = payload.stage;
                }
            },
        );

        builder.addCase(registerUser.rejected, (draft, { payload }) => {
            draft.isLoading = false;
            if (payload?.http_code && [409, 422].includes(payload.http_code)) {
                if (payload.code === BusinessErrorCode.REQUEST_IS_INCORRECT) {
                    draft.businessErrorCode =
                        draft.accountMethod === AccountMethodType.PHONE
                            ? BusinessErrorCode.INCORRECT_PHONE
                            : BusinessErrorCode.INCORRECT_EMAIL;
                    draft.stage = errorReturnStageSignUp(draft.businessErrorCode);
                } else if (payload.http_code === 409) {
                    draft.businessWarningCode = payload.code;
                    draft.stage = errorReturnStageSignUp(payload.code);
                } else {
                    draft.businessErrorCode = payload.code;
                    draft.stage = errorReturnStageSignUp(payload.code);
                }
            } else if (payload?.code) {
                draft.businessErrorCode = payload.code;
                draft.stage = errorReturnStageSignUp(payload.code);
            } else {
                draft.isError = true;
            }
        });

        builder.addCase(respondToRegisterChallenge.pending, (draft) => {
            draft.isLoading = true;
        });

        builder.addCase(respondToRegisterChallenge.fulfilled, (draft, { payload }) => {
            if (!payload.isContinueLoading) {
                draft.isLoading = false;
            }
            draft.challengeNames = payload.challengeNames;
            draft.lastUsername = payload.lastUsername;
            if (payload.stage) {
                draft.stage = payload.stage;
            }
        });

        builder.addCase(respondToRegisterChallenge.rejected, (draft, { payload }) => {
            if (payload?.code === TwoFAErrorCode.WRONG_CODE) {
                const twoFAError = payload as TwoFAError;
                if (twoFAError?.remaining_attempts_count > 0) {
                    draft.twoFAError = twoFAError;
                    draft.stage = errorReturnStageSignUp(draft.twoFAError?.code);
                } else {
                    draft.twoFAError = undefined;
                    draft.businessErrorCode = BusinessErrorCode.TOO_MANY_CHALLENGE_FAILURES;
                    draft.stage = errorReturnStageSignUp(draft.businessErrorCode);
                }
            } else {
                const error = payload as RegisterError;
                if (error?.http_code === 409) {
                    draft.businessWarningCode = error?.code;
                    draft.stage = errorReturnStageSignUp(draft.businessWarningCode);
                } else {
                    draft.businessErrorCode = error?.code;
                    draft.stage = errorReturnStageSignUp(draft.businessErrorCode);
                }
                draft.twoFAError = undefined;
            }
            draft.isLoading = false;
        });

        builder.addCase(respondToChallengeWithCaptcha.fulfilled, (draft) => {
            draft.captchaChallenge = {} as GeetestData;
        });

        builder.addCase(patchProfile.fulfilled, (draft) => {
            draft.isSuccessRegister = true;
        });

        builder.addCase(patchProfile.rejected, (draft, { payload }) => {
            draft.isLoading = false;
            draft.businessErrorCode = payload?.code;
        });

        builder.addCase(patchProfile.pending, (draft) => {
            draft.isLoading = true;
        });

        builder.addCase(registerResendCode.fulfilled, (draft) => {
            draft.isLoading = false;
        });

        builder.addCase(registerResendCode.rejected, (draft, { payload }) => {
            draft.twoFAError = payload;
            draft.isLoading = false;
        });

        builder.addCase(registerResendCode.pending, (draft) => {
            draft.isLoading = true;
            draft.twoFAError = undefined;
        });
    },
});

export const {
    setInitCompleted,
    reset,
    resetCaptcha,
    setAccountMethod,
    setStage,
    setLoading,
    setCaptchaError,
    setBusinessErrorCode,
    resetAllErrors,
    setSuccessRegister,
    setSocialMediaRegistrationSuccessful,
    logIn,
} = signUp.actions;
