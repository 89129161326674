import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Button, Flex, Menu } from "@noones/ui-kit";
import { En, Es, Fr } from "@noones/ui-kit/icons/24";

import { SearchParamNames } from "../../../features/auth/searchParamNames";
import { ReactComponent as EnFlag } from "../../../icons/en.svg";
import { ReactComponent as EsFlag } from "../../../icons/es.svg";
import { ReactComponent as FrFlag } from "../../../icons/fr.svg";
import { ReactComponent as RuFlag } from "../../../icons/ru.svg";
import { ReactComponent as Ru } from "../../../icons/ruButton.svg";
import { getLocale } from "../../../lib/locale";
import { Locale } from "../../../services/types";
import { selectApp } from "../../../store";
import { changeAppLocale } from "../../../store/app/app";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const LocaleSelect = () => {
    const languages = {
        [Locale.ENGLISH]: {
            label: "English",
            icon: <En />,
            flag: <EnFlag />,
        },
        [Locale.FRENCH]: {
            label: "Fran\u00e7ais",
            icon: <Fr />,
            flag: <FrFlag />,
        },
        [Locale.SPAIN]: {
            label: "Espa\u00f1ol",
            icon: <Es />,
            flag: <EsFlag />,
        },
        [Locale.RUSSIAN]: {
            label: "Русский",
            icon: <Ru />,
            flag: <RuFlag />,
        },
    };

    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const { locale: appLocale } = useAppSelector(selectApp);
    const locale = searchParams.get(SearchParamNames.LOCALE) || "";
    const nextUrl = searchParams.get(SearchParamNames.NEXT) || "";

    useEffect(() => {
        let paramsLocale = locale;

        if (nextUrl) {
            try {
                const next = new URL(decodeURIComponent(nextUrl));
                const nextLocaleParam = next.searchParams.get(SearchParamNames.LOCALE);
                if (nextLocaleParam) {
                    paramsLocale = nextLocaleParam;
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            }
        }

        dispatch(changeAppLocale(getLocale(paramsLocale)));
    }, [dispatch, locale, nextUrl]);

    const button = (
        <Button
            w={44}
            h={44}
            mode="tertiary"
            sx={(theme) => ({ color: theme.colors.textFixedDark })}
        >
            {languages[appLocale as Locale]?.icon}
        </Button>
    );

    const items = Object.keys(languages).map((key) => {
        const language = languages[key as Locale];
        return (
            <Flex
                key={key}
                h={40}
                w={155}
                gap={8}
                align="center"
                sx={{ padding: "0 16px" }}
                onClick={() => dispatch(changeAppLocale(key))}
            >
                {language?.flag}

                {language?.label}
            </Flex>
        );
    });

    return <Menu target={button} items={items} offset={0} position={"bottom-end"} />;
};
