import {
    BrowserClient,
    defaultStackParser,
    EventHint,
    Hub,
    Integrations,
    makeFetchTransport,
} from "@sentry/browser";

const sentry = new Hub();

export const initSentry = (dsn: string) => {
    sentry.bindClient(
        new BrowserClient({
            dsn,
            transport: makeFetchTransport,
            integrations: [new Integrations.Dedupe()],
            stackParser: defaultStackParser,
            normalizeDepth: 50,
        }),
    );

    sentry.setTag("app", "web-frontend");
    sentry.setTag("user-agent", window.navigator?.userAgent || "unknown");
    sentry.setTag("resolution", window.innerWidth + "x" + window.innerHeight);

    return sentry;
};

export type CaptureParams = {
    error: unknown;
    transactionName?: string;
    fingerprint: string[];
    tags?: Record<string, number | string | boolean | bigint | symbol | null | undefined>;
    context?: Record<string, Record<string, unknown> | null | undefined>;
    hint?: EventHint;
};

export const setUser = sentry.setUser.bind(sentry);

export class SentryError extends Error {
    constructor(message: string, name: string) {
        super(message);
        this.name = name;
    }
}

export const capture = ({
    transactionName,
    context,
    fingerprint,
    tags,
    hint,
    error,
}: CaptureParams) =>
    sentry.withScope((scope) => {
        scope.setFingerprint(fingerprint);

        if (transactionName) {
            scope.setTransactionName(transactionName);
        }

        if (tags) {
            Object.keys(tags).forEach((key) => scope.setTag(key, tags[key]));
        }

        if (context) {
            Object.keys(context).forEach((key) => {
                const value = context[key];

                if (value !== undefined) {
                    scope.setContext(key, value);
                }
            });
        }

        sentry.captureException(error, hint);
    });
