export enum StorageKeys {
    STATE = "profileState",
    NEXT = "profileNext",
    CLIENT_ID = "profileClientId",
    REDIRECT_URL = "profileRedirectUrl",
    ACCOUNTS_AUTHORIZE_URL = "profileAccountsAuthorizeUrl",
    ACTIVE_TAB = "profileActiveTab",
    EMAIL = "profileEmail",
    PHONE = "profilePhone",
    REFERRAL_CODE = "profileReferralCode",
    LOGIN_TOKEN = "profileLoginToken",
    IS_NOT_CITIZEN_ACCEPTED = "profileIsNotCitizen",
    LAST_USERNAME = "profileLastUsername",
    SESSION_ID = "profileSessionId",
}

export enum LocalStorageKeys {
    LOCALE = "profileLocale",
}

export enum FeatureFlag {
    USERNAME_SKIP = "username_skip",
    MERGE_EMAIL_PASSWORD = "merge_email_password",
    STEP1_NEW = "step1_new",
}

export const writeToStorage = (key: StorageKeys, value?: string | null) => {
    sessionStorage.setItem(key, value || "");
};

export const readFromStorage = (key: StorageKeys): string => sessionStorage.getItem(key) || "";

export const removeFromStorage = (key: StorageKeys): void => sessionStorage.removeItem(key);

export const readLocaleFromStorage = () => localStorage.getItem(LocalStorageKeys.LOCALE) || "";

export const writeLocaleToStorage = (locale: string) =>
    localStorage.setItem(LocalStorageKeys.LOCALE, locale || "");

export const getFeatureFlag = (name: string) => localStorage.getItem(name);

export const createFeatureFlag = (name: string, value: boolean) => {
    localStorage.setItem(name, String(value));
};

export const randomlyCreateFeatureFlag = (name: string) => {
    !isFeatureFlagExists(name) && createFeatureFlag(name, Math.random() < 0.5);
};

export const createTrueFeatureFlagIfNotExist = (name: string) => {
    !isFeatureFlagExists(name) && createFeatureFlag(name, true);
};

export const isFeatureFlagSet = (name: string) => getFeatureFlag(name) === "true";

export const isFeatureFlagExists = (name: string) => !!getFeatureFlag(name);
