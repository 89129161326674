import htmlMnemonicParser from "../features/phoneNumbers/htmlMnemonicParser";
import { responseHandler } from "../lib/responseHandler";
import { profileApi } from "./profileApi";
import {
    CountriesParamsResponse,
    Country,
    ForgotPasswordChallengesResponse,
    ForgotPasswordConfirmRequest,
    ForgotPasswordConfirmResponse,
    ForgotPasswordParams,
    ForgotPasswordRespondToChallengeRequest,
    ForgotPasswordResponse,
    GetReferralCodeRequest,
    GetReferralCodeResponse,
    GetSecurityQuestionsArg,
    GetSecurityQuestionsResponse,
    LoginParams,
    LoginParamsResponse,
    LoginRespondToChallengeRequest,
    LoginRespondToChallengeResponse,
    MfaParams,
    MfaResendCodeRequest,
    MfaRespondToChallengeRequest,
    MfaResponse,
    PutSecurityQuestionsArg,
    RegisterPatchProfileRequest,
    RegisterPatchProfileResponse,
    RegisterRequest,
    RegisterResendCodeRequest,
    RegisterRespondToChallengeRequest,
    RegisterRespondToChallengeResponse,
    RegisterResponse,
    ResetTwoFAArg,
    SettingsParamsResponse,
} from "./types";

export const authApi = profileApi
    .enhanceEndpoints({
        addTagTypes: ["User", "Country"],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            settings: builder.mutation<SettingsParamsResponse, void>({
                query: () => ({ url: "settings", method: "get", params: { platform: "web" } }),
                invalidatesTags: ["User"],
            }),

            countries: builder.query<Country[], void>({
                query: () => ({ url: "countries", method: "get" }),
                transformResponse: (response: CountriesParamsResponse) =>
                    response.items.map(({ name, iso, phone_code, compliance_group }) => ({
                        name: htmlMnemonicParser.decode(name),
                        iso,
                        phoneCode: phone_code,
                        complianceGroup: compliance_group,
                    })),
                providesTags: () => [{ type: "Country", id: "LIST" }],
            }),

            login: builder.mutation<LoginParamsResponse, LoginParams>({
                query: (body) => ({
                    url: "login",
                    method: "post",
                    responseHandler,
                    body,
                }),
                invalidatesTags: ["User"],
            }),

            respondToChallenge: builder.mutation<
                LoginRespondToChallengeResponse,
                LoginRespondToChallengeRequest
            >({
                query: (request) => ({
                    url: `login/${request.loginSessionId}/respond-to-challenge`,
                    method: "post",
                    // request,
                    responseHandler,
                    body: request.challenges,
                }),
                invalidatesTags: ["User"],
            }),

            getMfaLogin: builder.mutation<MfaResponse, MfaParams>({
                query: ({ login_session_id, mfa_session_id }) => ({
                    url: `login/${login_session_id}/mfa/${mfa_session_id}`,
                    method: "get",
                }),
            }),

            respondToMfaChallenge: builder.mutation<
                MfaResponse,
                MfaRespondToChallengeRequest & MfaParams
            >({
                query: ({
                    login_session_id,
                    mfa_session_id,
                    challenge_name,
                    challenge_response,
                }) => ({
                    url: `login/${login_session_id}/mfa/${mfa_session_id}/respond-to-challenge`,
                    method: "post",
                    body: {
                        challenge_name,
                        challenge_response,
                    },
                }),
            }),

            resendMfaCode: builder.mutation<MfaResponse, MfaResendCodeRequest & MfaParams>({
                query: ({ login_session_id, mfa_session_id, delivery_type }) => ({
                    url: `login/${login_session_id}/mfa/${mfa_session_id}/resend-code`,
                    method: "post",
                    body: { delivery_type },
                }),
            }),

            register: builder.mutation<RegisterResponse, RegisterRequest>({
                query: (body) => ({ url: "register", method: "post", body, responseHandler }),
                invalidatesTags: ["User"],
            }),

            respondToRegisterChallenge: builder.mutation<
                RegisterRespondToChallengeResponse,
                RegisterRespondToChallengeRequest
            >({
                query: (request) => ({
                    url: `register/${request.registerSessionId}/respond-to-challenge`,
                    method: "post",
                    // request,
                    responseHandler,
                    body: request.challenges,
                }),
                invalidatesTags: ["User"],
            }),

            registerPatchProfile: builder.mutation<
                RegisterPatchProfileResponse,
                RegisterPatchProfileRequest
            >({
                query: (params) => ({
                    url: `register/${params.registerSessionId}/profile`,
                    method: "PATCH",
                    body: params.body,
                }),
            }),

            resendCode: builder.mutation<
                RegisterRespondToChallengeResponse,
                RegisterResendCodeRequest
            >({
                query: (params) => ({
                    url: `register/${params.sessionId}/resend-code`,
                    method: "post",
                    body: params.body,
                }),
            }),

            forgotPassword: builder.mutation<ForgotPasswordResponse, ForgotPasswordParams>({
                query: (body) => ({
                    url: "forgot-password",
                    method: "post",
                    responseHandler,
                    body,
                }),
                invalidatesTags: ["User"],
            }),

            respondToForgotPasswordChallenge: builder.mutation<
                ForgotPasswordChallengesResponse,
                ForgotPasswordRespondToChallengeRequest
            >({
                query: (params) => ({
                    url: `forgot-password/${params.sessionId}/respond-to-challenge`,
                    body: params.challenges,
                    method: "post",
                    responseHandler,
                }),
                invalidatesTags: ["User"],
            }),
            resendCodeForgotPasswordChallenge: builder.mutation<
                ForgotPasswordChallengesResponse,
                Record<string, string | Record<string, string>>
            >({
                query: (params) => ({
                    url: `forgot-password/${params.sessionId}/resend-code`,
                    method: "post",
                    body: params.body,
                }),
            }),
            confirmForgotPassword: builder.mutation<
                ForgotPasswordConfirmResponse,
                ForgotPasswordConfirmRequest
            >({
                query: (params) => ({
                    url: `forgot-password/${params.sessionId}/confirm`,
                    body: { password: params.password },
                    method: "post",
                    responseHandler,
                }),
                invalidatesTags: ["User"],
            }),

            reset2fa: builder.mutation<void, ResetTwoFAArg>({
                query: (args) => ({
                    url: `login/${args.loginSessionId}/reset-2fa`,
                    method: "post",
                    responseHandler,
                    headers: {
                        "Content-Type": "application/json",
                    },
                }),
            }),

            getSecurityQuestions: builder.query<
                GetSecurityQuestionsResponse,
                GetSecurityQuestionsArg
            >({
                query: (args) => ({
                    url: `inquiries/${args.inquiryId}`,
                    method: "get",
                    responseHandler,
                }),
            }),

            putSecurityQuestions: builder.mutation<void, PutSecurityQuestionsArg>({
                query: (args) => {
                    const { inquiryId, ...body } = args;
                    return {
                        url: `inquiries/${inquiryId}`,
                        method: "put",
                        responseHandler,
                        body,
                    };
                },
            }),

            getReferralCode: builder.mutation<GetReferralCodeResponse, GetReferralCodeRequest>({
                query: (args) => {
                    const { referralCode } = args;
                    return {
                        url: `referral/${referralCode}`,
                        method: "get",
                        responseHandler,
                    };
                },
            }),
        }),
    });

export const {
    useSettingsMutation,
    useCountriesQuery,
    useLoginMutation,
    useRespondToChallengeMutation,
    useGetMfaLoginMutation,
    useRespondToMfaChallengeMutation,
    useResendMfaCodeMutation,
    useRespondToRegisterChallengeMutation,
    useResendCodeMutation,
    useRegisterMutation,
    useForgotPasswordMutation,
    useRespondToForgotPasswordChallengeMutation,
    useResendCodeForgotPasswordChallengeMutation,
    useConfirmForgotPasswordMutation,
    useGetSecurityQuestionsQuery,
    usePutSecurityQuestionsMutation,
} = authApi;
