import { profileApi } from "services/profileApi";

import {
    Action,
    AnyAction,
    configureStore,
    isRejectedWithValue,
    Middleware,
    ThunkAction,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { capture, SentryError } from "../../services/sentry";
import { app } from "../../store/app/app";
import { invite } from "../../store/invite/invite";
import { reset2FA } from "../../store/reset-2fa/reset-2fa";
import { resetPassword } from "../../store/reset-password/reset-password";
import { signIn } from "../../store/signin/signin";
import { signUp } from "../../store/signup/signup";

const protectedHeaders = ["X-CSRF-TOKEN", "Authorization"];

const requestErrorHandlerSentry = (action: AnyAction) => {
    const { meta, payload } = action;

    const {
        baseQueryMeta: { request, response },
        arg,
    } = meta;

    const headers = { ...request?.headers };

    protectedHeaders.forEach((k) => {
        delete headers[k];
    });

    const errorMessage = payload?.data ? JSON.stringify(payload?.data) : JSON.stringify(payload);

    capture({
        error: new SentryError(
            payload?.data?.message ?? response?.status ?? errorMessage,
            payload?.data?.code ?? "ApiError",
        ),
        transactionName: "Api",
        fingerprint: [request?.method ?? "?", request?.url ?? "?", response?.status ?? "?"],
        tags: { rest: true, "ui-branch": "beta" },
        context: {
            request: {
                method: request?.method,
                url: request?.url,
                headers,
                body: arg?.originalArgs,
            },
            response: {
                data: payload?.data ? payload?.data : payload,
            },
        },
    });
};

const redirectMiddleware: Middleware = () => (next) => (action) => {
    if (isRejectedWithValue(action) && /execute(Query|Mutation)\/rejected$/.test(action.type)) {
        const status = Number(action?.meta?.baseQueryMeta?.response?.status);
        if (isNaN(status) || status > 500) {
            requestErrorHandlerSentry(action);
        }
    }

    if (
        isRejectedWithValue(action) &&
        [action.payload?.data?.code, action.payload?.code].includes("countryNotAllowed")
    ) {
        if (/^profileApi*/.test(action.type)) {
            window.location.href = "/restricted";
        }
    } else {
        return next(action);
    }
};

export const store = configureStore({
    reducer: {
        [profileApi.reducerPath]: profileApi.reducer,
        [app.name]: app.reducer,
        [signUp.name]: signUp.reducer,
        [signIn.name]: signIn.reducer,
        [reset2FA.name]: reset2FA.reducer,
        [resetPassword.name]: resetPassword.reducer,
        [invite.name]: invite.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(redirectMiddleware).concat(profileApi.middleware),
    devTools: process.env.NODE_ENV === "development",
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
