import React, { ReactNode } from "react";

import { useClickOutside } from "@mantine/hooks";
import { Box, Flex, Text } from "@noones/ui-kit";
import { Info } from "@noones/ui-kit/icons/24";

export type AlertProps = {
    message: string | ReactNode;
    type?: "error" | "warning";
    onClickOutside: () => void;
};

export const Alert = ({ message, type = "warning", onClickOutside }: AlertProps) => {
    const ref = useClickOutside(onClickOutside, ["mouseup"]);

    const isError = type === "error";

    return (
        <Flex
            justify="center"
            w={"100%"}
            pos="absolute"
            top={44}
            left="50%"
            sx={{ transform: "translateX(-50%)" }}
        >
            <Box pos="absolute" maw={500} ref={ref} pl={20} pr={20}>
                <Flex direction="row">
                    <Flex
                        bg={isError ? "backgroundNegative" : "backgroundAlert"}
                        w={4}
                        mt={4}
                        mb={4}
                    />

                    <Flex
                        bg={isError ? "backgroundNegative" : "backgroundAlert"}
                        pt={12}
                        pr={8}
                        pb={12}
                        pl={8}
                        gap={12}
                        w={"100%"}
                    >
                        <Box h={24} c={isError ? "iconsNegative" : "iconsAlert"}>
                            <Info />
                        </Box>

                        <Flex align="center">
                            <Text
                                size="subheadMSemibold"
                                c={isError ? "textNegative" : "textAlert"}
                            >
                                {message}
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex
                        bg={isError ? "backgroundNegative" : "backgroundAlert"}
                        w={4}
                        mt={4}
                        mb={4}
                    />
                </Flex>
            </Box>
        </Flex>
    );
};
