import { Locale } from "../services/types";
import { readLocaleFromStorage } from "./storage";

interface Navigator {
    language?: string;
    systemLanguage?: string;
    userLanguage?: string;
}

const getBrowserLanguage = () => {
    const navigator = window.navigator as Navigator;
    if (navigator) {
        const language = navigator.language || navigator.systemLanguage || navigator.userLanguage;
        if (language && language.length >= 2) {
            const locale = language.substring(0, 2).toLowerCase();
            if (Object.values<string>(Locale).includes(locale)) {
                return locale;
            }
        }
    }
    return "en";
};

export const getLocale = (locale: string) => {
    const loc = locale || readLocaleFromStorage() || getBrowserLanguage();
    const normalizedLocale = loc.substring(0, 2).toLowerCase();
    if (Object.values<string>(Locale).includes(normalizedLocale)) {
        return normalizedLocale;
    }
    return "en";
};
