import React from "react";

import { Overlay, OverlayProps } from "@mantine/core";
import { Flex, Loader } from "@noones/ui-kit";

export type LoaderMaskedSurface = OverlayProps;

export const LoaderMaskedSurface = (props: LoaderMaskedSurface) => (
    <Overlay {...props}>
        <Flex w="100%" h="100%" justify="center" align="center">
            <Loader size={48} />
        </Flex>
    </Overlay>
);
