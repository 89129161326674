import { RootState } from "../app/store/store";
import { app } from "./app/app";
import { invite } from "./invite/invite";
import { reset2FA } from "./reset-2fa/reset-2fa";
import { resetPassword } from "./reset-password/reset-password";
import { signIn } from "./signin/signin";
import { signUp } from "./signup/signup";

export const selectApp = (state: RootState) => state[app.name];
export const selectSignUp = (state: RootState) => state[signUp.name];
export const selectSignIn = (state: RootState) => state[signIn.name];
export const selectResetPassword = (state: RootState) => state[resetPassword.name];
export const selectReset2FA = (state: RootState) => state[reset2FA.name];
export const selectInvite = (state: RootState) => state[invite.name];
