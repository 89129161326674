// based on config/i18n.php
/* eslint-disable */
const locales: { [key: string]: { fullLocaleCode: string } } = {
    en: { fullLocaleCode: "en_US" },
    ru: { fullLocaleCode: "ru_RU" },
    zh_CN: { fullLocaleCode: "zh_CN" },
    zh_TW: { fullLocaleCode: "zh_TW" },
    es: { fullLocaleCode: "es_ES" },
    pt: { fullLocaleCode: "pt_PT" },
    pt_BR: { fullLocaleCode: "pt_BR" },
    fr: { fullLocaleCode: "fr_FR" },
    de: { fullLocaleCode: "de_DE" },
    it: { fullLocaleCode: "it_IT" },
    id: { fullLocaleCode: "id_ID" },
    tr: { fullLocaleCode: "tr_TR" },
    ja: { fullLocaleCode: "ja_JP" },
    vi: { fullLocaleCode: "vi_VN" },
    ms: { fullLocaleCode: "ms_MY" },
    tl: { fullLocaleCode: "tl_PH" },
    ko: { fullLocaleCode: "ko_KR" },
    cs: { fullLocaleCode: "cs_CZ" },
    pl: { fullLocaleCode: "pl_PL" },
    sv_SE: { fullLocaleCode: "sv_SE" },
    nl_NL: { fullLocaleCode: "nl_NL" },
    ar: { fullLocaleCode: "ar_SA" },
    da: { fullLocaleCode: "da_DK" },
    et: { fullLocaleCode: "et_EE" },
    lt: { fullLocaleCode: "lt_LT" },
    lv: { fullLocaleCode: "lv_LV" },
    fi: { fullLocaleCode: "fi_FI" },
    nb: { fullLocaleCode: "nb_NO" },
    ha: { fullLocaleCode: "ha_HG" },
    af: { fullLocaleCode: "af_ZA" },
    yo: { fullLocaleCode: "yo_NG" },
    sw: { fullLocaleCode: "sw_KE" },
    ig: { fullLocaleCode: "ig_NG" },
    xh: { fullLocaleCode: "xh_ZA" },
    zu: { fullLocaleCode: "zu_ZA" },
    lg: { fullLocaleCode: "lg_UG" },
    so: { fullLocaleCode: "so_SO" },
    uk: { fullLocaleCode: "uk_UA" },
    bg: { fullLocaleCode: "bg_BG" },
    sl: { fullLocaleCode: "sl_SI" },
    km: { fullLocaleCode: "km_KH" },
    hi: { fullLocaleCode: "hi_IN" },
    el: { fullLocaleCode: "el_GR" },
    hu: { fullLocaleCode: "hu_HU" },
    ro: { fullLocaleCode: "ro_RO" },
    sr: { fullLocaleCode: "sr_CS" },
    ur: { fullLocaleCode: "ur_PK" },
    pcm: { fullLocaleCode: "pcm_NG" },
    he: { fullLocaleCode: "he_IL" },
    mn: { fullLocaleCode: "mn" },
};

export const toFullLocale = (locale: string) => locales[locale]?.fullLocaleCode;
