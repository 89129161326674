import React, { useCallback } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { Button, Flex, Text } from "@noones/ui-kit";

export const messages = defineMessages({
    usePhoneNumber: {
        id: "Auth.Account.Form.Controls.AccountMethod.UsePhoneNumber",
        defaultMessage: "Use phone number instead",
        description: "Use phone number instead",
    },
    useEmailNumber: {
        id: "Auth.Account.Form.Controls.AccountMethod.UseEmailNumber",
        defaultMessage: "Use email address instead",
        description: "Use email address instead",
    },
});

export enum AccountMethodType {
    PHONE = "phone",
    EMAIL = "email",
}

export type AccountMethodProps = {
    activeMethod: AccountMethodType;
    accountMethodChange: (method: AccountMethodType) => void;
    tabIndex: number;
};

export const AccountMethod = ({
    activeMethod,
    accountMethodChange,
    tabIndex,
}: AccountMethodProps) => {
    const isActiveMail = activeMethod === AccountMethodType.EMAIL;

    const handleAccountTypeChange = useCallback(
        (type: AccountMethodType) => accountMethodChange(type),
        [accountMethodChange],
    );
    const byPhone = () => handleAccountTypeChange(AccountMethodType.PHONE);
    const byEmail = () => handleAccountTypeChange(AccountMethodType.EMAIL);

    return (
        <Flex w="100%" justify="center">
            <Button tabIndex={tabIndex} mode="tertiary" onClick={isActiveMail ? byPhone : byEmail}>
                <Text size="subheadMSemibold" c="textAction">
                    {isActiveMail ? (
                        <FormattedMessage {...messages.usePhoneNumber} />
                    ) : (
                        <FormattedMessage {...messages.useEmailNumber} />
                    )}
                </Text>
            </Button>
        </Flex>
    );
};
