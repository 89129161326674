export const responseHandler = async (response: Response) => {
    const text = await response.text();
    try {
        const json_rv = text.length ? JSON.parse(text) : null;
        if (json_rv) {
            return { ...json_rv, http_code: response.status };
        }
        return {};
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return {};
    }
};
