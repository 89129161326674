export type APIErrorResponse<RequestType> = {
    message: string;
    code: string;
} & Partial<RequestType>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorData<RequestType>(error: any) {
    return error && "data" in error && error.data
        ? (error.data as APIErrorResponse<RequestType>)
        : undefined;
}

export type LoginPasswordChallengeResponse = {
    email?: string;
    phone?: string;
    password: string;
};

export type CaptchaChallengeResponse = {
    challenge: string;
    seccode: string;
    validate: string;
};

export type CastleIOChallengeResponse = {
    castleio_request_token: string;
};

export type LoginChallengeResponse = {
    challenge_name: ChallengeType;
    challenge_response?:
        | LoginPasswordChallengeResponse
        | CaptchaChallengeResponse
        | CastleIOChallengeResponse;
};

export type LoginRespondToChallengeRequest = {
    loginSessionId: string;
    challenges: Array<LoginChallengeResponse>;
};

export type AuthResult = {
    token_type: string;
    login_token: string;
};

export type LoginRespondToChallengeResponse = {
    login_session_id?: string;
    auth_result?: AuthResult;
    challenges?: Array<LoginAuthFlowChallenge>;
    http_code: number;
};

export type LoginMfaChallengeParameters = {
    mfa_session_id: string;
    mfa_access_token: string;
    reset_allowed: boolean;
};

export type CaptchaChallengeParameters = {
    offline: boolean;
    captcha_id: string;
    challenge: string;
};

export type PasswordChallengeParameters = {
    email: string;
};

export enum ChallengeType {
    username_password = "username_password",
    mfa = "mfa",
    geetest = "geetest",
    castleio = "castleio_request_token",
    password = "password",
}

export type LoginAuthFlowChallenge = {
    challenge_name: ChallengeType;
    challenge_parameters:
        | LoginMfaChallengeParameters
        | CaptchaChallengeParameters
        | PasswordChallengeParameters
        | undefined;
};

export type Oauth2NextParams = {
    next: string;
};

export type SettingsParamsResponse = {
    config: {
        accounts_authorize_url: string;
        marketplace_url: string;
        marketplace_client_id: string;
        signup_enabled: boolean;
        signup_by_phone_enabled: boolean;
        signup_by_email_enabled: boolean;
        waiting_list_url: string;
        support_url: string;
        complex_regulation_countries: string[];
        gamification_awards_enabled: boolean;
        escrow_fee_discount_enabled: boolean;
        signup_by_google_enabled: boolean;
        signup_by_facebook_enabled: boolean;
        signup_by_apple_enabled: boolean;
        google_register_url: string;
        google_login_url: string;
        facebook_register_url: string;
        facebook_login_url: string;
        apple_login_url: string;
        apple_register_url: string;
    };
    registration_info: {
        citizenship_consent_required: boolean;
        ip_address: string;
        ip_country: string;
    };
};

export type Country = {
    name: string;
    iso: string;
    phoneCode: number;
    // 0 - country blocked, 1 - country has complex regulation
    complianceGroup?: number;
};

export type CountriesParamsResponse = {
    items: {
        name: string;
        iso: string;
        phone_code: number;
        compliance_group?: number;
    }[];
};

export type LoginParams = {
    type?: AuthType;
    code?: string;
    platform: string;
    app_version: string;
    client_id: string;
};

export type LoginParamsResponse = {
    login_session_id?: string;
    challenges?: Array<LoginAuthFlowChallenge>;
    auth_result?: AuthResult;
    http_code: number;
};

export type VerifyRegisterRequest = {
    user_id: string;
    timestamp: string;
    signature: string;
};

export type ChangePasswordRequest = {
    old_password: string;
    password: string;
    password_confirm: string;
};

export type SendResetPasswordLinkRequest = {
    email: string;
};

export type SendResetPasswordLinkResponse = {
    detail: string;
};

export type ResetPasswordParams = {
    user_id: string;
    timestamp: string;
    signature: string;
};

export type ResetPasswordRequest = {
    password: string;
    password_confirm: string;
} & ResetPasswordParams;

export type ResetPasswordResponse = {
    detail: string;
};

export type User = {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    username: string;
};

export enum MfaChallengeType {
    phone = "phone",
    email = "email",
    google_authenticator = "google_authenticator",
}
export type MfaChallenge = {
    challenge_name: MfaChallengeType;
    challenge_parameters: MfaPhoneChallengeParameters | undefined;
};

export type MfaPhoneChallengeParameters = {
    phone_number?: string;
    email?: string;
    delivery_type?: string;
    resend_remaining_seconds?: number;
    supported_delivery_types?: Array<string>;
    reset_allowed?: boolean;
};

export type MfaRespondToChallengeRequestParameters = {
    code: string;
};

export type MfaRespondToChallengeRequest = {
    challenge_name: MfaChallengeType;
    challenge_response: MfaRespondToChallengeRequestParameters;
};

export type MfaResendCodeRequest = {
    delivery_type: string;
};

export enum ChallengeStatus {
    in_progress = "in_progress",
    success = "success",
    failed = "failed",
}

export type MfaParams = {
    login_session_id: string;
    mfa_session_id: string;
};

export type MfaResponse = {
    mfa_session_id: string;
    status: ChallengeStatus;
    challenges: Array<MfaChallenge>;
};

export type PhoneRegisterChallengeParameters = {
    delivery_type: DeliveryType;
    resend_remaining_seconds: number;
    supported_delivery_types: Array<string>;
};
export type CaptchaRegisterChallengeParameters = CaptchaChallengeParameters;

export type CastleIORegisterChallengeParameters = CastleIOChallengeResponse;

export type EmailRegisterChallengeParameters = {
    delivery_type: DeliveryType;
    resend_remaining_seconds: number;
    supported_delivery_types: Array<string>;
};

export type PasswordRegisterChallengeParameters = {
    email: string;
};

export enum RegisterChallengeType {
    email = "email",
    phone = "phone",
    geetest = "geetest",
    castleio = "castleio_request_token",
    password = "password",
    mfa = "mfa",
}

export type RegisterAuthFlowChallenge = {
    challenge_name: RegisterChallengeType;
    challenge_parameters:
        | CaptchaRegisterChallengeParameters
        | PhoneRegisterChallengeParameters
        | EmailRegisterChallengeParameters
        | PasswordRegisterChallengeParameters
        | CastleIORegisterChallengeParameters
        | LoginMfaChallengeParameters
        | undefined;
};

export type AuthType = "apple" | "google" | "facebook" | "email" | "phone";

export type SocialMediaAccountType = "apple" | "google" | "facebook";

export type SetSocialMediaCodeArg = {
    type: SocialMediaAccountType;
    code: string;
};

export type SetSocialMediaErrorArg = {
    type: SocialMediaAccountType;
    error: string;
};

export type RegisterRequest = {
    locale: string;
    password?: string;
    email?: string;
    phone?: string;
    type?: AuthType;
    code?: string;
    citizenship_consent_accepted?: boolean;
    platform: string;
    app_version: string;
    client_id: string;
    device_id: string;
    referral_code?: string;
};

export type RegisterResponse = {
    register_session_id?: string;
    login_session_id?: string;
    challenges: Array<RegisterAuthFlowChallenge>;
    http_code: number;
    auth_result?: AuthResult;
    username?: string;
};

export type RegisterCaptchaChallengeResponse = {
    challenge: string;
    seccode: string;
    validate: string;
};

export type RegisterCodeChallengeResponse = {
    code: string;
};

export type RegisterChallengeResponse = {
    challenge_name: RegisterChallengeType;
    challenge_response?:
        | RegisterCodeChallengeResponse
        | RegisterCaptchaChallengeResponse
        | CastleIOChallengeResponse;
};

export type RegisterRespondToChallengeRequest = {
    registerSessionId: string;
    challenges: Array<RegisterChallengeResponse>;
};

export type RegisterRespondToChallengeResponse = {
    register_session_id?: string;
    challenges?: Array<RegisterAuthFlowChallenge>;
    auth_result?: AuthResult;
    username?: string;
    http_code: number;
};

export type RegisterPatchProfileRequest = {
    registerSessionId: string;
    body: {
        username?: string;
        referral_code?: string;
    };
};

export type RegisterPatchProfileResponse = {
    username: string;
    email: string;
    email_verified: boolean;
    phone: string;
    phoneVerified: boolean;
    firstName: string;
    lastName: string;
    middleName: string;
};

export type RegisterResendCodeRequest = {
    sessionId: string;
    body: {
        delivery_type: DeliveryType;
    };
};

export enum DeliveryType {
    sms = "sms",
    voice = "voice",
    email = "email",
}

export type ForgotPasswordByEmail = {
    email: string;
};
export type ForgotPasswordByPhone = {
    phone: string;
};
export type ForgotPasswordParams = ForgotPasswordByEmail | ForgotPasswordByPhone;

export type ForgotPasswordCaptchaChallengeParameters = CaptchaChallengeParameters;

export type ForgotPasswordPhoneChallengeParameters = PhoneRegisterChallengeParameters & {
    phone_number?: string;
};

export type ForgotPasswordMailChallengeParameters = EmailRegisterChallengeParameters & {
    email?: string;
};

export enum ForgotPasswordChallengeType {
    geetest = "geetest",
    email = "email",
    phone = "phone",
    castleio = "castleio_request_token",
}

export type ForgotPasswordAuthFlowChallenge = {
    challenge_name: ForgotPasswordChallengeType;
    challenge_parameters:
        | ForgotPasswordCaptchaChallengeParameters
        | ForgotPasswordPhoneChallengeParameters
        | ForgotPasswordMailChallengeParameters;
};

export type ForgotPasswordResponse = {
    forgot_password_session_id: string;
    status: ChallengeStatus;
    challenges: Array<ForgotPasswordAuthFlowChallenge>;
};

export type ForgotPasswordChallengesResponse = {
    forgot_password_session_id: string;
    status: ChallengeStatus;
    challenges?: Array<ForgotPasswordAuthFlowChallenge>;
    http_code: number;
};

export type ForgotPasswordRespondToChallengeRequest = {
    sessionId: string;
    challenges: Array<ForgotPasswordChallengeResponse>;
};

export type PhoneChallengeResponse = {
    code: string;
};

export type ForgotPasswordChallengeResponse = {
    challenge_name: ForgotPasswordChallengeType;
    challenge_response:
        | PhoneChallengeResponse
        | CaptchaChallengeResponse
        | CastleIOChallengeResponse;
};

export type ForgotPasswordConfirmRequest = {
    sessionId: string;
    password: string;
};

export type ForgotPasswordConfirmResponse = {
    http_code: number;
};

export type ResetTwoFAArg = {
    loginSessionId: string;
};

export type Question = {
    key: string;
    question: string;
};

export type Answer = {
    key: string;
    answer: string;
};

export type GetSecurityQuestionsArg = {
    inquiryId: string;
};

export type GetSecurityQuestionsResponse = {
    security_questions: Question[];
};

export type AttachFileArgs = {
    inquiryId: string;
    slug: string;
    file: File;
};

export type PutSecurityQuestionsArg = {
    phone?: string;
    inquiryId: string;
    security_questions: Answer[];
};

export type BaseError = {
    code: string;
    message: string;
};

export type TwoFAError = BaseError & {
    remaining_attempts_count: number;
};

export type HttpError = {
    status: number;
    data: BaseError;
};

export type ResetTwoFAError = BaseError | TwoFAError;

export type FormType = {
    email: string;
    phone: string;
    password: string;
    twoFA: string;
    username: string;
    referralCode: string;
};

export type GetReferralCodeRequest = {
    referralCode: string;
};

export type GetReferralCodeResponse = {
    uid: string;
    referrer_revenue_share: string;
    avatar_url: string;
    bio: string;
    username: string;
    badges: [];
    feedback_positive: number;
    feedback_negative: number;
    trades_count_total?: number;
    trades_percent_success?: number;
};

export enum Locale {
    ENGLISH = "en",
    FRENCH = "fr",
    SPAIN = "es",
    RUSSIAN = "ru",
    // VIETNAMESE = "vi",
    // CHINESE = "zh",
}

export enum AccountPageType {
    SIGN_IN = "sign_in",
    SIGN_UP = "sign_up",
    RESET_PASSWORD = "reset_password",
}

export enum BusinessErrorCode {
    PASSWORD_TOO_SIMPLE = "passwordTooSimple",
    INVALID_LOGIN_PASSWORD = "invalidLoginPassword",
    INVALID_GOOGLE_LOGIN = "invalidGoogleLogin",
    INVALID_FACEBOOK_LOGIN = "invalidFacebookLogin",
    INVALID_APPLE_LOGIN = "invalidAppleLogin",
    PHONE_AUTH_NOT_ALLOWED = "phoneAuthNotAllowed",
    TOO_MANY_CHALLENGE_FAILURES = "tooManyChallengeFailures",
    REQUEST_IS_INCORRECT = "requestIsIncorrect",
    INCORRECT_EMAIL = "incorrectEmail",
    INCORRECT_PHONE = "incorrectPhone",
    EMAIL_CANNOT_CONTAIN_ALIAS = "emailCannotContainAlias",
    PHONE_COUNTRY_NOT_ALLOWED = "phoneCountryNotAllowed",
    EMAIL_BLACKLISTED = "emailBlacklisted",
    INVALID_REFERRAL_CODE = "invalidReferralCode",
    USER_ALREADY_EXISTS = "userAlreadyExists",
    MFA_METHOD_NOT_CONFIGURED = "mfaMethodNotConfigured",
    INVALID_USERNAME = "invalidUsername",
    OAUTH_INVALID_CODE = "oauthInvalidCode",
    SOCIAL_MEDIA_ERROR = "socialMediaError",
}

export enum BusinessWarningCode {
    USER_ALREADY_EXISTS = "userAlreadyExists",
}

export enum InvitationWarningCode {
    OAUTH_INVALID_CODE = "oauthInvalidCode",
    SOCIAL_MEDIA_ERROR = "socialMediaError",
    USER_ALREADY_EXISTS = "userAlreadyExists",
}

export enum TwoFAErrorCode {
    WRONG_CODE = "WrongCode",
    TOO_MANY_REQUESTS = "tooManyRequests",
    LINK_EXPIRED = "reset2FALinkExpired",
}

export enum ResetTwoFAErrorCode {
    REQUEST_IS_INCORRECT = "requestIsIncorrect",
    WRONG_ANSWER = "WrongAnswer",
    RESET_2FA_LINK_EXPIRED = "reset2FALinkExpired",
    RESOURCE_NOT_FOUND = "resourceNotFound",
}
