/* eslint-disable */
import React, { useState, useLayoutEffect } from "react";
import { IntlProvider, IntlConfig } from "react-intl";

import { toFullLocale } from "./helpers/toFullLocale";
import { handleTranslationError } from "./helpers/translationErrorHandler";

import { getMessages, getSupportedLocales } from "./index";

// Motivation
// https://github.com/formatjs/formatjs/issues/2237#issuecomment-714773610
// http://cldr.unicode.org/translation/translation-guide-general/default-content
const getLocaleInCLDRForm = (locale: string) => locale?.slice(0, 2);

// Motivation
// Inside monolith we use sometimes locale and sometimes language(like ru), but in crowdin we use only locale.
// In order to fix this, we check is locale support, and if not we convert it to crowdin locale format
const getLocalizedMessages = (locale: string) => {
    const supportedLocales = getSupportedLocales();
    return supportedLocales.includes(locale)
        ? getMessages(locale)
        : getMessages(toFullLocale(locale));
};

export const ProfileIntlProvider = ({
    children,
    locale,
}: {
    children: React.ReactNode;
    locale: string;
}) => {
    const [messages, setMessages] = useState<IntlConfig["messages"] | null>(null);

    useLayoutEffect(() => {
        let isCancelled = false;

        async function setMessagesAsync() {
            const fetchedMessages = await getLocalizedMessages(locale);
            !isCancelled && setMessages(fetchedMessages);
        }

        setMessagesAsync().finally();

        return () => {
            isCancelled = true;
        };
    }, [locale, setMessages]);

    return (
        <>
            {messages && (
                <IntlProvider
                    onError={handleTranslationError}
                    messages={messages}
                    locale={getLocaleInCLDRForm(locale)}
                    defaultLocale="en"
                >
                    {children}
                </IntlProvider>
            )}
        </>
    );
};
